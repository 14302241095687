import { rest } from 'msw';
import { getProfilEndPoint } from '../utils/endpoint';
import { sessionExpired, invalidCampaignToken } from './signin';
import { cookieServerName, res } from './utils';

export const errorHandler = (error, message) => ({ error, message });

let error = 0;

global.profilePublic = {
	status: 'new',
	consents: {
		eula: false,
		dpp: false,
	},
	technicalTests: {},
};
// TODO 403 on extention profile v7d20350k3f99tf ppprevost1@aol.fr

// classic profile of a user
global.profile = {
	faqUrl: 'https://easyrecruesupport.zendesk.com/hc/fr',
	returnUrl: 'https://www.teamtailor.com/en/',
	id: 'mockFront_' + Math.random().toString(36).substr(2, 9),
	timerEnd: null,
	needRequisite: true,
	status: 'started',
	email: 'ppprevost@aol.fr',
	firstName: 'tata',
	lastName: 'toto',
	consents: {
		eula: false,
		dpp: false,
	},
	technicalInformation: null,
	technicalTests: {},
	language: 'fr',
	videoResults: {
		isVideoOk: true,
		isAudioOk: true,
		url: 'https://static.staging.easyrecrue.tech/clients/102_49e64a808bcc22fe1ff9e19fbd8be33c/tbcampaigns/35_65a4df4eae55693142dffcfc2dae3300/tbcandidates/507_e6385f7294ac4bb8ceaff30fb644d0ab/videos/507-0.mp4?t=1605274084',
	},
	requisites: [],
};

export default rest.get(getProfilEndPoint, (req, response, ctx) => {
	if (document.cookie.includes(cookieServerName + '=' + sessionExpired)) {
		const status = 403;
		return res(ctx.status(status, 'Mocked status'));
	}
	if (document.cookie.includes(cookieServerName + '=' + invalidCampaignToken)) {
		const status = 403;
		return res(
			ctx.status(status, 'Mocked status'),
			ctx.json(errorHandler(status, 'EXPIRED_CAMPAIGN'))
		);
	}

	if (document.cookie.includes(cookieServerName)) {
		let actualProfile = document.cookie.includes('joined=true') ? 'profilePublic' : 'profile';
		if (globalThis.testProfile === true) {
			global.profile.timerEnd = 100;
		}

		let status = 200;
		if (error >= 2 && error < 3) {
			//status = 504;
			global.profile.timerEnd = 20;
		}
		error++;

		return res(
			ctx.status(status, 'Mocked status'),
			ctx.json(status === 200 ? global[actualProfile] : { message: 'Error' })
		);
	}
	return res(ctx.status(401, 'Mocked unauthorized'));
});
export const patchProfile = rest.patch(getProfilEndPoint, (req, res, ctx) => {
	let actualProfile = document.cookie.includes('joined=true') ? 'profilePublic' : 'profile';
	global[actualProfile] = {
		...global[actualProfile],
		...req.body,
	};
	if (req.body.technicalInformation && req.body.technicalInformation['bandwidthUp']) {
		// test error bandwidth
		//return res(ctx.status(403, "Mocked status"));
	}

	//test if public campaign with an existing user that fill an existing email. default is joined. Test with finished or started
	if (global[actualProfile].status === 'new' || global[actualProfile].status === 'invited') {
		global[actualProfile].status = 'joined';
		global[actualProfile].needRequisite = true;
	}

	let status = 204;

	console.log('global.profile: ', global[actualProfile], req.body);
	return res(ctx.status(status, 'Mocked status'));
});
