import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sprintf from 'i18next-sprintf-postprocessor';
import HttpApi from 'i18next-http-backend';
import { toast } from 'react-toastify';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const initI18n = (clientId = '0', lng = 'en-GB') =>
	i18n
		// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
		// learn more: https://github.com/i18next/i18next-http-backend
		// detect user language
		// learn more: https://github.com/i18next/i18next-browser-languageDetectorxs
		// pass the i18n instance to react-i18next.
		.use(initReactI18next)
		.use(sprintf)
		.use(HttpApi)
		// init i18next
		// for all options read: https://www.i18next.com/overview/configuration-options
		.init(
			{
				compatibilityJSON: 'v3',
				load: 'currentOnly',
				fallbackLng: lng,
				lng,
				debug: process.env.NODE_ENV === 'development',
				backend: {
					allowMultiLoading: false,
					crossDomain: false,
					loadPath: `/api/v1/config/i18n/${clientId ? `${clientId}/` : ''}${lng.replace('-', '_')}`,
					requestOptions: {
						// used for fetch, can also be a function (payload) => ({ method: 'GET' })
						cache: 'no-cache',
					},
				},
				postProcess: 'sprintf',
				nsSeparator: false,
				keySeparator: false,
				interpolation: {
					escapeValue: false,
				},
			},
			(err) => {
				if (err) {
					toast.error('Une erreur est survenue :(');
				}
				window.i18nInit = true;
			}
		);

export default initI18n;
