import { memo } from 'react';
import Navbar from '../../../library/Navbar';
import logo from '../../../img/Icims_logo.png';
import { Box } from 'rebass/styled-components';
import { H1, Text } from '../../../library';
import { useTranslation } from '../../../hooks/useTranslation';
import { string } from 'prop-types';
import { mailER } from '../../../utils/config';

const AuthError = memo(({ content = null }) => {
	const { t } = useTranslation();

	return (
		<Box>
			<Navbar logo={logo} />
			<Box>
				<H1 color="#174b80;" fontSize="24px" mb={3}>
					{content ??
						t('Your interview or test is not yet available on the platform.') +
							' ' +
							t('Please reconnect later.')}
				</H1>
				<Text textAlign="center">
					{t('Feel free to contact your recruiter for further information.')}
				</Text>
			</Box>
		</Box>
	);
});

AuthError.propTypes = {
	content: string,
};

export default AuthError;
