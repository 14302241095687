import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const RTCInitialState = {
	// to launch the action start anywhere
	startRecordAction: false,
	// to launch the action stop anywhere
	stopRecordAction: false,
	// chrono timeToRead
	chronoReadIsStarted: false,
	videoIsRecording: false,
	seeCarousel: false,
	videoRecordCompleted: false,
	// time between timeFromAnswer et currentTime make activated the button to stop the test
	canFinishRecord: false,
	error: null,
};

export const initialState = {
	// usefull for video media template
	showButtons: true,
	videoIsFinished: false,
	// show the drawer on the question, only in freeNavigation
	displayPlanNavigation: false,
	// useless
	currentAnswer: null,
	// global TImer is launched
	timerIsActive: false,
	timerQuestionIsStarted: false,
	loading: false,
	videoRTC: RTCInitialState,
	testFinished: false,
	warningEndQuestion: false,
};

export const sectionsSlice = createSlice({
	name: 'sections',
	initialState,
	reducers: {
		TOGGLE_PLAN_NAVIGATION: (state) => {
			state.displayPlanNavigation = !state.displayPlanNavigation;
		},
		START_TIMER: (state, { payload }) => {
			state.timerIsActive = payload;
		},
		SHOW_BUTTONS: (state, { payload }) => {
			state.showButtons = payload;
		},
		VIDEO_IS_FINISHED: (state, { payload }) => {
			state.videoIsFinished = payload;
		},
		SET_CURRENT_ANSWER: (state, { payload }) => {
			state.currentAnswer = payload;
		},
		INIT_QUESTION_TIMER: (state, { payload }) => {
			state.timerQuestionIsStarted = payload;
		},
		SET_LOADING: (state, { payload }) => {
			state.loading = payload;
		},
		MAJ_RTC: (state, { payload }) => {
			state.videoRTC = { ...state.videoRTC, ...payload };
		},
		SET_TEST_FINISHED: (state) => {
			state.testFinished = true;
		},
		SET_WARNING_END_QUESTION: (state, { payload }) => {
			state.warningEndQuestion = payload;
		},
		SET_SEE_CAROUSEL: (state, { payload }) => {
			state.seeCarousel = payload;
		},
	},
});

/**
 * @typedef {Object} Store
 * @property {typeof initialState} sections
 * @property {string} introText
 * @property {any[]} medias
 */

/**
 *
 * @param {Store} state
 * @return {*}
 */
export const selectSection = (state) => state.sections;
/**
 *
 * @return {initialState}
 */
export const useSectionState = () => useSelector(selectSection);

export const useLoadingSectionState = () => useSelector((state) => state.sections.loading);

/**
 *
 * @return {*|{startRecordAction: boolean, stopRecordAction: boolean, videoIsRecording: boolean, chronoReadIsStarted: boolean, error: null, seeCarousel: boolean, videoRecordCompleted: boolean, canFinishRecord: boolean}}
 */
export const useVideoRTCState = () => useSelector((state) => state.sections.videoRTC);
export default sectionsSlice.reducer;

export const useSelectorQuerySections = (query) => useSelector((state) => state.sections[query]);
