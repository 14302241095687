import { rest } from 'msw';
import examMockConfig from './workflow/template/examMockConfig';
import { getPreviewEndPoint } from '../utils/endpoint';

const tokenPreview = 'tokenPreview';

export default rest.get(getPreviewEndPoint + `/:token`, (req, res, ctx) => {
	console.info('preview route');
	if (req.params.token === tokenPreview) {
		return res(ctx.delay(500), ctx.status(200, 'Mocked status'), ctx.json(examMockConfig));
	}
	return res(ctx.status(403, 'Mocked Forbiden'));
});
