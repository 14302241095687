import { setupWorker } from 'msw';
import {
	campaign,
	signin,
	later,
	sections,
	getSurvey,
	postSurvey,
	navigation,
	extend,
	document,
	proctoring,
	cgu,
	uploadBigFile,
	legal,
	preview,
	entered,
	notinterested,
	extensionStatus,
	requisiteDocument,
	patchProfile,
	profile,
	finish,
	current,
	requisite,
	postQuestion,
} from './mocksAPI';

// 2. Define request handlers and response resolvers
const worker = setupWorker(
	signin,
	campaign,
	requisite,
	postQuestion,
	extend,
	cgu,
	finish,
	current,
	legal,
	requisiteDocument,
	entered,
	extensionStatus,
	document,
	getSurvey,
	postSurvey,
	notinterested,

	later,
	profile,
	uploadBigFile,
	proctoring,
	preview,
	patchProfile,
	navigation,
	sections
);

// 3. Start the Service Worker
worker.start();
