import { useEffect, useState } from 'react';
import useConfigRemote from '../data/useConfigRemote';
import initI18n from '../i18n';

const withCheckIfCampaignExist = (Component) => (props) => {
	const [basicTrad, setBasicTrad] = useState(false);

	const {
		data: { AVAILABLE_LOCALES },
	} = useConfigRemote();

	useEffect(() => {
		(async function check() {
			if (!window.i18nInit) {
				let browserLanguage = window.navigator.language;

				if (browserLanguage.length === 2) {
					const langRegExp = new RegExp(`^${browserLanguage}.*`, 'g');
					const matchLanguage = AVAILABLE_LOCALES.find((lang) => langRegExp.test(lang));

					browserLanguage = matchLanguage ? matchLanguage : 'en-US';
				} else {
					browserLanguage = browserLanguage.replace('-', '_');
				}
				await initI18n(
					'0',
					AVAILABLE_LOCALES.indexOf(browserLanguage) > -1
						? browserLanguage.replace('_', '-')
						: 'en-US'
				);
				return setBasicTrad(true);
			}
			return setBasicTrad(true);
		})();
	}, [AVAILABLE_LOCALES]);

	if (basicTrad) {
		return <Component {...props} />;
	}
	return null;
};

export default withCheckIfCampaignExist;
