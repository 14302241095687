import { useCallback } from 'react';
import { useTranslation as useTranslationI18n } from 'react-i18next';
import { captureException } from '@sentry/react';
/**
 * Wrapper to get xgettext working
 * With this wrapper you could manage sing and plural form to be compatible with xgettext
 * @returns {{t: (function(...[*]): string)}}
 */
export const useTranslation = () => {
	const { t: tr } = useTranslationI18n();

	const t = useCallback(
		(...args) => {
			const [sing, plural, options] = args;
			const trad = tr(sing, typeof plural === 'string' ? options : plural);

			if (!trad) {
				captureException(new Error('Traduction Missing'));
				console.error(sing);
			}
			return trad;
		},
		[tr]
	);

	return {
		t,
	};
};

export default useTranslation;
