import useCampaignData from '../data/useCampaignData';
import { string } from 'prop-types';
import { memo } from 'react';

const withCampaignStyles = (Component, typeOfProperty = 'bg') => {
	const Wrapped = memo(({ ...props }) => {
		const {
			data: { styles },
		} = useCampaignData();

		const type = { [typeOfProperty]: styles.buttonBackground };

		return <Component {...type} {...props} />;
	});

	Wrapped.propTypes = {
		typeOfProperty: string,
	};
	return Wrapped;
};

export default withCampaignStyles;
