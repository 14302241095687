import { memo } from 'react';
import styled, { css } from 'styled-components';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Box } from 'rebass/styled-components';
import { position, layout, space, typography, display, flexbox } from 'styled-system';
import { node, string, number, bool } from 'prop-types';

export const RoundedBox = styled.div`
	${({ theme }) => css`
		${theme.mediaQueries.small} {
			padding: 15px 30px 30px 30px;
		}
	`};
	padding: 15px 20px 30px 20px;
	border: none;
	position: ${({ position }) => position};
	border-radius: 10px;
	background-color: ${({ theme, displayPlanNavigation }) =>
		displayPlanNavigation ? '7888a9' : theme.colors.white};
	box-shadow: 0 2px 53px 0 #e5dede;
	margin-bottom: 15px;
	transition: all 1s ease-in-out;
	${space};
	${position};
	${layout};
	${typography};
	${flexbox};
	${display};
`;

export const CenterCol = styled(Col)`
	margin: 0 auto;
`;

const Round = memo(
	({
		maxHeightMobile = true,
		overflow = 'hidden',
		relativeParent = false,
		children,
		colMd = 8,
		colLg = 12,
		textAlign = 'center',
		...props
	}) => (
		<Container {...props}>
			<RoundedBox
				width="100%"
				overflow={overflow}
				minHeight={[maxHeightMobile ? '80vh' : '500px', 'initial']}
				textAlign={textAlign}
				mt={['1.3rem', '0']}
			>
				<Box style={{ position: relativeParent ? 'relative' : 'initial' }}>
					<CenterCol
						style={{
							position: relativeParent ? 'initial' : 'relative',
						}}
						lg={colLg}
						md={colMd}
					>
						{children}
					</CenterCol>
				</Box>
			</RoundedBox>
		</Container>
	)
);

Round.propTypes = {
	maxHeightMobile: bool,
	overflow: string,
	relativeParent: bool,
	children: node,
	colMd: number,
	colLg: number,
	textAlign: string,
};

export default Round;
