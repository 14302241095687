import { useState, useCallback, useMemo, forwardRef, useRef, useImperativeHandle } from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'rebass/styled-components';
import { func, bool, number, string, oneOfType, node } from 'prop-types';
import VideoOrImageTpl from '../components/Intro/Landing/shared/VideoImgTpl';
import useInterval from '../hooks/useInterval';
import { ChevronRight, ChevronLeft } from '@styled-icons/material';
import { mediasProps } from '../types/propTypes';
import { captureException } from '@sentry/react';
import useTranslation from '../hooks/useTranslation';

const Items = styled.div`
	position: absolute;
	top: 45%;
	width: 100%;
	left: 0;
	user-select: none;
	transform: translate(0%, -50%);
	${({ theme }) => css`
		${theme.mediaQueries.medium} {
			left: 50%;
			transform: translate(-50%, -50%);
		}
	`};
`;

const Item = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	border-radius: ${({ theme }) => theme.image.borderRadius};
	transform: translate(-50%, -50%);
	max-height: 300px;
	${({ theme }) => css`
		${theme.mediaQueries.small} {
			max-height: 350px;
			max-width: 500px;
		}
		${theme.mediaQueries.medium} {
			max-width: 500px;
		}
	`};
	overflow: hidden;
	transition: all 300ms ease-in-out;
	z-index: -1;
	opacity: 0;
	${({ active }) =>
		active &&
		css`
			width: 100%;
			opacity: 1;
			z-index: 99;
			box-shadow: 0 0 105px -35px rgba(0, 0, 0, 0.75);
		`};
	${({ theme, next }) =>
		next &&
		css`
			pointer-events: none;
			${theme.mediaQueries.medium} {
				opacity: ${({ freeze }) => (freeze ? '0' : '0.25')};
				z-index: 2;
				transform: translate(45%, -50%);
				max-width: 380px;
			}
		`};

	${({ theme, prev }) =>
		prev &&
		css`
			pointer-events: none;
			${theme.mediaQueries.medium} {
				z-index: 2;
				opacity: ${({ freeze }) => (freeze ? '0' : '0.25')};
				max-width: 380px;
				transform: translate(-145%, -50%);
			}
		`};
`;

const ButtonContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -40%);
	max-height: 400px;
	width: 100%;
	height: 0px;
	margin-top: -8%;
	${({ theme }) => css`
		${theme.mediaQueries.medium} {
			width: 610px;
			margin-top: -2%;
		}
	`};
	z-index: 100;
`;

const Button = styled.button`
	color: #fff;
	font-size: 32px;
	outline: none;
	cursor: pointer;
	position: relative;
	opacity: ${({ opacity }) => opacity ?? 0.75};
	transition: ${({ transition }) => (transition ? 'none' : 'all 300ms ease-in-out')};
	border: none;
	background-color: transparent;
	${({ theme }) => css`
		${theme.mediaQueries.medium} {
			width: 50px;
		}
		&:hover {
			opacity: ${({ opacity }) => opacity ?? 1};
		}
	`}

	&:focus-visible {
		border-color: #80bdff;
		outline: 3px;
		width: 50px;
		box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%);
	}
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 30px;
		height: 120px;
		${({ theme }) => css`
			${theme.mediaQueries.medium} {
				height: 50px;
				width: 50px;
				border-radius: 50%;
			}
		`};
		border-radius: 5px;
		background-color: ${({ colorNextButton, theme }) =>
			colorNextButton ?? theme.colors.gray2 ?? 'gray'};

		z-index: -99;
	}

	&:nth-child(1) {
		float: left;
	}
	&:nth-child(2) {
		float: right;
	}
`;

const CarouselIndicator = styled.ol`
	position: absolute;
	bottom: 10px;
	left: 50%;
	z-index: 15;
	width: 60%;
	padding-left: 0;
	margin-left: -30%;
	text-align: center;
	list-style: none;
`;

const CarouselList = styled.li`
	display: inline-block;
	width: 25px;
	${({ theme }) => css`
		${theme.mediaQueries.medium} {
			width: 35px;
		}
	`};
	margin-left: 10px;
	border: 0 solid;
	height: 8px;
	text-indent: -999px;
	border-radius: 10px;
	cursor: pointer;
	background-color: ${({ active, theme }) => (active ? theme.colors.black : theme.colors.gray4)};
`;

// TODO no calc needed https://css-tricks.com/full-width-containers-limited-width-parents/
/* const CarouselContainer = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;*/

const Carousel = forwardRef(
	(
		{
			slides,
			playColor = '#000',
			onSelect = () => null,
			activePreviousButton = true,
			autoplay = false,
			onClickNext = null,
			slideshowSpeed = 20000,
			showIndicator = true,
			colorNextButton = null,
			buttonBackComponent = null,
			buttonNextComponent = null,
			freeze = false,
			disableButtons = false,
			currentSlide = 1,
		},
		ref
	) => {
		const { t } = useTranslation();
		const backButton = t('Previous');
		const nextButton = t('Next');
		const [current, setCurrent] = useState(currentSlide - 1);
		const [, setPlay] = useState(true);
		const [initPlay, setInitPlay] = useState(false);
		const buttonNextRef = useRef(null);
		const next = useMemo(() => {
			if (current + 1 >= slides.length) {
				return 0;
			}
			return current + 1;
		}, [current, slides.length]);

		const prev = useMemo(() => {
			if (current - 1 < 0) {
				return slides.length - 1;
			}
			return current - 1;
		}, [current, slides.length]);

		const gotoNum = useCallback(
			(numberCarousel) => {
				if (onSelect) {
					onSelect(numberCarousel);
				}
				if (initPlay && slides[current].type === 'video') {
					setPlay(false);
				}

				return setCurrent(numberCarousel);
			},
			[initPlay, current, onSelect, slides]
		);

		const goToPrev = useCallback(
			() => (current > 0 ? gotoNum(current - 1) : gotoNum(slides.length - 1)),
			[current, gotoNum, slides.length]
		);

		const goToNext = useCallback(
			() => (current < slides.length - 1 ? gotoNum(current + 1) : gotoNum(0)),
			[current, gotoNum, slides.length]
		);

		useImperativeHandle(ref, () => ({
			get buttonNext() {
				return buttonNextRef.current;
			},
		}));

		useInterval(
			() => {
				goToNext();
			},
			autoplay ? slideshowSpeed : null
		);

		return (
			<Box height={['80vw', '400px']} width="100%" style={{ position: 'relative' }}>
				<Items>
					{slides.map((elem, i) => (
						<Item
							freeze={freeze}
							prev={i === prev}
							next={i === next}
							key={i}
							active={i === current}
							aria-hidden={i !== current}
							aria-disabled={i !== current}
						>
							<VideoOrImageTpl
								onError={(e) => {
									captureException('VideoImageTpl Error', e);
								}}
								aria-hidden={i !== current}
								playColor={playColor}
								active={i === current}
								playing={i === current}
								media={elem}
							/>
						</Item>
					))}

					{!freeze && (
						<ButtonContainer>
							<Button
								transition={buttonNextComponent !== 'hide'}
								opacity={freeze || buttonBackComponent === 'hide' ? 0 : 1}
								disabled={disableButtons || buttonBackComponent === 'hide'}
								style={{ visibility: activePreviousButton ? 'inherit' : 'hidden' }}
								onClick={goToPrev}
								aria-label={backButton}
								aria-hidden={freeze || buttonBackComponent === 'hide'}
							>
								{buttonBackComponent ?? (
									<ChevronLeft style={{ verticalAlign: 'initial' }} size={24} />
								)}
							</Button>

							<Button
								colorNextButton={buttonNextComponent && colorNextButton}
								opacity={freeze ? 0 : 1}
								disabled={disableButtons || buttonNextComponent === 'hide'}
								ref={buttonNextRef}
								onClick={!onClickNext ? goToNext : onClickNext}
								aria-label={nextButton}
							>
								{buttonNextComponent ?? (
									<ChevronRight style={{ verticalAlign: 'initial' }} size={24} />
								)}
							</Button>
						</ButtonContainer>
					)}
				</Items>
				{!freeze && showIndicator && (
					<CarouselIndicator aria-hidden>
						{slides.map((elem, i) => (
							<CarouselList
								onClick={() => (!disableButtons ? gotoNum(i) : null)}
								key={`carousel${i}`}
								active={i === current}
							/>
						))}
					</CarouselIndicator>
				)}
			</Box>
		);
	}
);

Carousel.propTypes = {
	slides: mediasProps.isRequired,
	onSelect: func,
	onClickNext: func,
	colorNextButton: string,
	freeze: bool,
	buttonBackComponent: oneOfType([node, string]),
	buttonNextComponent: oneOfType([node, string]),
	activePreviousButton: bool,
	disableButtons: bool,
	showIndicator: bool,
	currentSlide: number,
	playColor: string,
	autoplay: bool,
	slideshowSpeed: number,
};

export default Carousel;
