import { Box } from 'rebass';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Span } from '../library';
import ExtraPropTypes from 'react-extra-prop-types';
import Label from './Label';
import { useEffect, useRef } from 'react';
import withCampaignStyles from '../HOC/withCampaignStyles';

const caseWidth = 19;

const Input = styled.input`
	position: absolute;
	z-index: -1;
	opacity: 0;
	outline: none;
	left: 0;
	top: 2px;
	padding: 0;
	width: ${`${caseWidth}px`};
	height: ${`${caseWidth}px`};
	border: none;
	margin: 0;
	&:focus-visible ~ span {
		font-weight: bolder;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
	}
`;

const Case = styled.span`
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	background-color: ${({ checked, bg }) => (checked ? bg : 'white')};
	border: 1px solid ${({ checked, bg }) => (checked ? bg : '#d4d4d4')};
	position: absolute;
	top: 3px;
	left: 0;
	height: ${`${caseWidth}px`};
	width: ${`${caseWidth}px`};
	border-radius: 2px;
	&:hover {
		filter: contrast(0.95);
	}

	${({ checked }) =>
		checked &&
		`
    &::after {
    display: block;
    left: 6px;
    box-sizing: unset;
    content: '';
    position: absolute;
    top: 1px;
    width: 5px;
    height: 12px;
    border: solid #FFFFFF;
    border-width: 0 1px 1px 0;
    transform: rotate(40deg);
}`}
`;

export const CheckBox = ({
	label = '',
	mb = 2,
	mt = 1,
	bg = '#000',
	checked = false,
	disabled = false,
	// eslint-disable-next-line react/prop-types
	'data-testId': testId = 'nothing',
	...props
}) => {
	const checkboxRef = useRef(null);

	useEffect(() => {
		const handleClick = (e) => {
			e.preventDefault();
			if (e.keyCode === 32) {
				props.onChange(e);
			}
		};
		const checkbox = checkboxRef.current;

		checkbox?.addEventListener('keyup', handleClick);
		return () => checkbox?.removeEventListener('keyup', handleClick);
	}, [props]);

	return (
		<Box mb={mb} mt={mt}>
			<Label htmlFor={props.id} data-testid={testId} disabled={disabled}>
				<Input
					ref={checkboxRef}
					type="checkbox"
					checked={Boolean(checked)}
					disabled={disabled}
					{...props}
				/>
				<Span>{label}</Span>
				<Case data-testid="checkbox" bg={bg} checked={Boolean(checked)} />
			</Label>
		</Box>
	);
};

CheckBox.propTypes = {
	id: PropTypes.any,
	mt: PropTypes.number,
	mb: PropTypes.number,
	checked: PropTypes.bool,
	testId: PropTypes.string,
	disabled: PropTypes.bool,
	bg: ExtraPropTypes.color,
	onChange: PropTypes.func,
	label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default withCampaignStyles(CheckBox);
