import { useEffect, useRef } from 'react';

/**
 * Usefull for timer, countdown
 * @param {Function} callback - callback to launch on the setInterval
 * @param {Number} delay - in millisecond !
 * @returns {number}
 */
const useInterval = (callback, delay) => {
	const intervalId = useRef(0);
	const savedCallback = useRef(null);

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			intervalId.current = setInterval(tick, delay);
		}
		return () => clearInterval(intervalId.current);
	}, [delay]);
	return intervalId.current;
};

export default useInterval;
