import { useEffect, useState, memo } from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';
import { Img } from '../../library';
import useCampaignData from '../../data/useCampaignData';
import { string, bool } from 'prop-types';
import { Box } from 'rebass/styled-components';

const stretchdelay = keyframes`
  0%, 40%, 100% { -webkit-transform: translateY(-10px) }  
      20% { -webkit-transform: translateY(-20px) }
`;

const slidesFade = (position) => keyframes`
 0% { opacity: 0;
 transform:translateY(${position})
  }
 20% { opacity: 1; transform: translateY(0) }
 
 75%{
 opacity: 1;
 }
 100%{
 opacity: 0;
 }
`;
const slidesFade2 = (position) => keyframes`
 0% { opacity: 0;
 transform:translateY(${position})
  }
 40% { opacity: 1; transform: translateY(0) }
 
 75%{
 opacity: 1;
 }
 100%{
 opacity: 0;
 }
`;

const anim = (position) => css`
	animation: ${slidesFade(position)} 2s cubic-bezier(0.755, 0.05, 0.855, 0.06) forwards;
`;

const ImgFade = styled(Img)`
	${anim('-30px')}
`;

const ContainerSplash7 = styled.div`
	width: 90px;
	height: 30px;
	animation: ${({ withImg }) => withImg && slidesFade2('30px')} 2s
		cubic-bezier(0.755, 0.05, 0.855, 0.06) forwards;
	div {
		background-color: ${({ bg }) => bg};
		height: 15px;
		width: 15px;
		margin-left: 3px;
		border-radius: 50%;
		display: inline-block;
		animation: ${stretchdelay} 0.7s infinite ease-in-out;
	}

	.circ1 {
		animation-delay: 1s;
	}

	.circ2 {
		animation-delay: 1.1s;
	}
	.circ3 {
		animation-delay: 1.2s;
	}
	.circ4 {
		animation-delay: 1.3s;
	}
	.circ5 {
		animation-delay: 1.4s;
	}
`;

const Screen = styled.div`
	background-color: white;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 9999999999;
`;

const SpinnerSplash = memo(({ bg, withImg }) => (
	<ContainerSplash7 withImg={withImg} bg={bg}>
		<div className="circ1" />
		<div className="circ2" />
		<div className="circ3" />
		<div className="circ4" />
	</ContainerSplash7>
));

SpinnerSplash.propTypes = {
	bg: string,
	withImg: bool,
};

export const SplashScreen = memo(({ withImg = false, splashScreenPrinted = false }) => {
	const { data } = useCampaignData(withImg);
	const theme = useTheme();
	const [show, setShow] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => setShow(true), withImg ? (splashScreenPrinted ? 2000 : 0) : 0);

		return () => {
			clearTimeout(timeout);
		};
	}, [splashScreenPrinted, withImg]);

	return (
		<Box>
			{show && (
				<Screen data-testid="splash-screen">
					{withImg && <ImgFade alt={data.title} mb={4} maxWidth="240px" src={data.logo} />}
					<SpinnerSplash
						withImg={withImg}
						bg={data?.styles?.buttonBackground ?? theme.colors.gray4}
					/>
				</Screen>
			)}
		</Box>
	);
});

SplashScreen.propTypes = {
	withImg: bool,
	splashScreenPrinted: bool,
};

export default SplashScreen;
