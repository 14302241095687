import styled, { css } from 'styled-components';
import { color, typography, space, layout, border, system, compose } from 'styled-system';

export const titleCustom = compose(system({ whiteSpace: true }), color, typography, space, layout);

export const H3 = styled.h3`
	font-family: ${({ theme }) => theme.fonts.body};
	margin-top: 12px;
	color: ${({ theme }) => theme.colors.black}
		${({ theme }) => css`
			${theme.mediaQueries.large} {
				margin-top: 18px;
			}
		`};
	font-size: ${({ theme }) => theme.title.h3.fontSize};
	margin-bottom: 10px;
	${system({ whiteSpace: true })};
	${titleCustom}
`;
H3.displayName = 'H3';

export const H2 = styled.h2`
font-family: ${({ theme }) => theme.fonts.body};
  margin-top: 20px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.black}
  font-family: inherit;
font-size: ${({ theme }) => theme.title.h2.fontSize};
 font-weight: ${({ theme }) => theme.fontWeights.lightBold};
  line-height: 1.1;
 
  ${color} ${typography} ${space} ${layout} ${border}
`;
H2.displayName = 'H2';

export const H1 = styled.h1`
	font-family: ${({ theme }) => theme.fonts.body};
	text-align: center;
	font-weight: ${({ theme }) => theme.fontWeights.bolder};
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 20px;
	font-size: ${({ theme }) => theme.title.h1.fontSize};
	${color} ${typography} ${space} ${layout}${border}
`;
H1.displayName = 'H1';

export const H4 = styled.h4`
	font-family: ${({ theme }) => theme.fonts.body};
	font-size: ${({ theme }) => theme.title.h4.fontSize};
	color: ${({ theme }) => theme.colors.black} ${color} ${typography} ${space} ${color} ${typography}
		${space} ${layout} ${border};
`;
H4.displayName = 'H4';
