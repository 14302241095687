import styled from 'styled-components';
import { Span } from '../library';
import PropTypes from 'prop-types';
import ExtraPropTypes from 'react-extra-prop-types';
import Label from './Label';
import { Box } from 'rebass';
import withCampaignStyles from '../HOC/withCampaignStyles';
import { useEffect, useRef } from 'react';

const Input = styled.input`
	position: absolute;
	opacity: 0;
	&:focus-visible ~ span {
		font-weight: bolder;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
	}
`;

const CustomRadioIndicator = styled.span`
	background-color: ${({ checked, bg }) => (checked ? bg : 'white')};
	position: absolute;
	top: 3px;
	cursor: pointer;
	left: 0;
	height: 19px;
	width: 19px;
	border-radius: 50%;
	border: 1px solid ${({ checked, bg }) => (checked ? bg : '#d4d4d4')};
	${({ disabled }) => disabled && `cursor: not-allowed`};
	${({ checked }) =>
		checked &&
		`&::after {
    display: block;
    top: 6px;
    left: 6px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #ffffff;
    content: "";
    position: absolute;
}`}
`;

export const Radio = ({ label, name, bg, checked, margin, labelStyle, disabled, id, ...props }) => {
	const checkboxRef = useRef(null);

	useEffect(() => {
		const handleClick = (e) => {
			e.preventDefault();
			if (e.keyCode === 32) {
				props.onChange(e);
			}
		};
		const checkbox = checkboxRef.current;

		checkbox?.addEventListener('keyup', handleClick);
		return () => checkbox?.removeEventListener('keyup', handleClick);
	}, [props]);

	return (
		<Box width="100%">
			<Label disabled={disabled} margin={margin} className="EC-radio">
				<Input
					aria-checked={Boolean(checked)}
					ref={checkboxRef}
					id={id}
					{...props}
					disabled={disabled}
					checked={Boolean(checked)}
					type="radio"
					name={name}
				/>
				<Span {...labelStyle}>{label}</Span>
				<CustomRadioIndicator disabled={disabled} checked={Boolean(checked)} {...props} bg={bg} />
			</Label>
		</Box>
	);
};

Radio.propTypes = {
	bg: ExtraPropTypes.color,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	margin: PropTypes.number,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	tabindex: PropTypes.string,
	disabled: PropTypes.bool,
	labelStyle: PropTypes.shape(),
	name: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

Radio.defaultProps = {
	checked: false,
	onChange: () => {},
	margin: 0,
	disabled: false,
	bg: '#000',
	label: '',
	name: '',
};

export default withCampaignStyles(Radio);
