import { combineReducers } from "@reduxjs/toolkit";
import sectionReducer from "./sectionReducer";
import appReducer from "./appReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistAppConfig = {
  key: "app",
  storage,
  whitelist: ["testTechnique"],
};

const rootReducer = combineReducers({
  app: persistReducer(persistAppConfig, appReducer),
  sections: sectionReducer,
});

export default rootReducer;
