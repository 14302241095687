/**
 *SWR
 */

export const SWRConfigValue = {
	onError: (err) => console.log('SWR error', err),
	shouldRetryOnError: false,
	suspense: true,
	revalidateOnFocus: false,
	revalidateOnMount: false,
	revalidateOnReconnect: false,
};

export const minBandwidthUp = 36000;

export const enableMediaRecorder = true;

export const mailER = 'supportemea@icims.com';

export const MAX_VIDEOS_RERECORDING = 3;

export const icimsLogoUrl = '/icims_logo.png';
