import { getSectionsEndpoint } from './endpoint';
import { enableMediaRecorder } from './config';

export function hexToRgbA(hex, opacity = 1) {
	let c = null;

	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = `0x${c.join('')}`;
		return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
	}
	throw new Error('Bad Hex');
}

export const getUrlSpecificQuestion = (sectionNumber = 0, questionNumber = 0) =>
	`${getSectionsEndpoint}/${sectionNumber}/${questionNumber}`;

export function isInteger(n) {
	return n === Number(n) && n === (n | 0);
}

export function bytesToSize(bytes) {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	if (bytes === 0) return '0 Byte';
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

	return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
}

/* eslint-disable */
export const isMobile = (function (a) {
	return (
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
			a
		) ||
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
			a.substr(0, 4)
		)
	);
	// @ts-ignore
})(navigator.userAgent || navigator.vendor || window.opera);
/* eslint-enable */

export const wait = (time = 2000) =>
	new Promise((resolve) => {
		const i = setTimeout(() => {
			resolve();
			clearTimeout(i);
		}, time);
	});

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'Mac OS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (/Linux/.test(platform)) {
		os = 'Linux';
	}

	return os;
}

export function getBrowserAndVersion() {
	const navUserAgent = navigator.userAgent;

	let browserName = navigator.appName;

	let browserVersion = String(parseFloat(navigator.appVersion));

	// let majorVersion = parseInt(navigator.appVersion, 10);
	let tempNameOffset = null;

	let tempVersionOffset = null;

	let tempVersion = null;

	if ((tempVersionOffset = navUserAgent.indexOf('Opera')) !== -1) {
		browserName = 'Opera';
		browserVersion = navUserAgent.substring(tempVersionOffset + 6);
		if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1)
			browserVersion = navUserAgent.substring(tempVersionOffset + 8);
	} else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) !== -1) {
		browserName = 'Microsoft Internet Explorer';
		browserVersion = navUserAgent.substring(tempVersionOffset + 5);
	} else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) !== -1) {
		browserName = 'Chrome';
		browserVersion = navUserAgent.substring(tempVersionOffset + 7);
	} else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) !== -1) {
		browserName = 'Safari';
		browserVersion = navUserAgent.substring(tempVersionOffset + 7);
		if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1)
			browserVersion = navUserAgent.substring(tempVersionOffset + 8);
	} else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) !== -1) {
		browserName = 'Firefox';
		browserVersion = navUserAgent.substring(tempVersionOffset + 8);
	} else if (
		(tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) <
		(tempVersionOffset = navUserAgent.lastIndexOf('/'))
	) {
		browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
		browserVersion = navUserAgent.substring(tempVersionOffset + 1);
		if (browserName.toLowerCase() === browserName.toUpperCase()) {
			browserName = navigator.appName;
		}
	}

	// trim version
	if ((tempVersion = browserVersion.indexOf(';')) !== -1)
		browserVersion = browserVersion.substring(0, tempVersion);
	if ((tempVersion = browserVersion.indexOf(' ')) !== -1)
		browserVersion = browserVersion.substring(0, tempVersion);

	return { browserName, browserVersion };
}

export function forceDownload(blob, filename) {
	const a = document.createElement('a');

	a.download = filename;
	a.href = blob;
	// For Firefox https://stackoverflow.com/a/32226068
	document.body.appendChild(a);
	a.click();
	a.remove();
}

// Current blob size limit is around 500MB for browsers
export function downloadResource(url, filename) {
	if (!filename) filename = url.split('\\').pop().split('/').pop();
	return fetch(url, {
		headers: new Headers({
			Origin: location.origin,
		}),
		mode: 'cors',
	})
		.then((response) => response.blob())
		.then((blob) => {
			const blobUrl = window.URL.createObjectURL(blob);

			forceDownload(blobUrl, filename);
		});
}

export const isMediaRecorder = () => enableMediaRecorder && window.MediaRecorder;

export const fetcher = async (url, cache = 'no-cache') => {
	const res = await fetch(url, { cache });

	// If the status code is not in the range 200-299,
	// we still try to parse and throw it.
	if (!res.ok) {
		const error = {};

		// Attach extra info to the error object.
		try {
			error.info = await res.json();
		} catch (err) {
			error.info = '';
		}

		error.status = res.status;
		throw error;
	}
	return res.json();
};

export const getRandomId = () => Math.random().toString(36).substr(2, 9);

export async function fetchWithTimeout(resource, options = {}) {
	const { timeout = 8000 } = options;

	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);

	const response = await fetch(resource, {
		headers: { 'Content-Type': 'application/json' },
		cors: 'no-cors',
		...options,
		...(timeout > 0 ? { signal: controller.signal } : {}),
	});

	clearTimeout(id);

	return response;
}

export const calculateBitrate = (bitUpload = 40000) => {
	const bitrate = {
		40000: 128,
		65000: 200,
		73000: 264,
		90000: 300,
		100000: 400,
		140000: 650,
		200000: 750,
		500000: 1000,
	};

	for (let i = Object.keys(bitrate).length - 1; i > 0; i--) {
		if (bitUpload * 0.85 > parseInt(Object.keys(bitrate)[i], 10)) {
			return Object.values(bitrate)[i] * 1000;
		}
	}
	return 128000;
};

export function retryCallChunk(fn, retriesLeft = 5, interval = 1000) {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error);
						return;
					}

					// Passing on "reject" is the important part
					retryCallChunk(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
}

// https://css-tricks.com/converting-color-spaces-in-javascript/

/**
 *
 * @param H
 * @param {'array' |'text'} format
 * @returns {(number|number)[]|string|null}
 */
export function hexToHSL(H, format = 'text') {
	// Convert hex to RGB first
	let r = 0,
		g = 0,
		b = 0;

	if (H.length === 4) {
		r = `0x${H[1]}${H[1]}`;
		g = `0x${H[2]}${H[2]}`;
		b = `0x${H[3]}${H[3]}`;
	} else if (H.length === 7) {
		r = `0x${H[1]}${H[2]}`;
		g = `0x${H[3]}${H[4]}`;
		b = `0x${H[5]}${H[6]}`;
	}
	// Then to HSL
	r /= 255;
	g /= 255;
	b /= 255;
	let cmin = Math.min(r, g, b),
		cmax = Math.max(r, g, b),
		delta = cmax - cmin,
		h = 0,
		s = 0,
		l = 0;

	if (delta === 0) h = 0;
	else if (cmax === r) h = ((g - b) / delta) % 6;
	else if (cmax === g) h = (b - r) / delta + 2;
	else h = (r - g) / delta + 4;

	h = Math.round(h * 60);

	if (h < 0) h += 360;

	l = (cmax + cmin) / 2;
	s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
	s = +(s * 100).toFixed(1);
	l = +(l * 100).toFixed(1);

	if (format === 'text') {
		return `hsl(${h},${s}%,${l}%)`;
	}
	if (format === 'array') {
		return [h, s, l];
	}
	return null;
}

export function getUrlExtension(url) {
	return url.split(/[#?]/)[0].split('.').pop().trim();
}
