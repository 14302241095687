import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components';

export default styled(({ show, content, target }) => (
	<OverlayTrigger
		target={target.current}
		show={show}
		placement="top"
		delay={{ show: 250, hide: 400 }}
	>
		{(props) => <Tooltip {...props}>{content}</Tooltip>}
	</OverlayTrigger>
))`
	background-color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fonts.body};
`;
