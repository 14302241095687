import { useCallback, useEffect, useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import 'video.js/dist/video-js.css';
import { getRandomId } from '../../utils/tools';

const Controls = {
	play: 'playToggle',
	volume: 'volumePanel',
	seekbar: 'progressControl',
	timer: 'remainingTimeDisplay',
	playbackrates: 'playbackRateMenuButton',
	fullscreen: 'fullscreenToggle',
};

const VideoPlayer = memo(
	({
		src,
		active = true,
		hideControls,
		onPause,
		onError,
		onEnd,
		onSeeking,
		onTimeUpdate,
		onPlay,
		onReady,
		onSeeked,
		...props
	}) => {
		const [player, setPlayer] = useState({});

		const generatePlayerOptions = useCallback(() => {
			const playerOptions = {
				controls: props.controls,
				playsinline: true,
				disablePictureInPicture: true,
				autoplay: props.autoplay,
				pictureInPictureToggle: false,
				preload: props.preload,
				liveDisplay: false,
				width: props.width,
				height: props.height,
				bigPlayButton: props.bigPlayButton,
			};

			const hidePlaybackRates = props.hidePlaybackRates || hideControls.includes('playbackrates');

			if (!hidePlaybackRates) playerOptions.playbackRates = props.playbackRates;
			return playerOptions;
		}, [
			hideControls,
			props.controls,
			props.autoplay,
			props.preload,
			props.width,
			props.height,
			props.playbackRates,
			props.hidePlaybackRates,
			props.bigPlayButton,
		]);

		useEffect(() => {
			if (Object.keys(player).length && !active) {
				Object.values(Controls).forEach((control) => {
					player.controlBar[control].hide();
				});
				return player.bigPlayButton.hide();
			}
			return player.bigPlayButton?.show();
		}, [active, player]);

		useEffect(() => {
			let currentTime = 0;

			let previousTime = 0;

			let position = 0;

			player.on?.('error', () => {
				onError?.(player);
			});

			player.on?.('play', () => {
				player.bigPlayButton.hide();
				onPlay?.(player.currentTime());
			});
			player.on?.('pause', () => {
				player.bigPlayButton.show();
				onPause?.(player.currentTime());
			});
			player.on?.('timeupdate', () => {
				onTimeUpdate?.(player.currentTime());
				previousTime = currentTime;
				currentTime = player.currentTime();
				if (previousTime < currentTime) {
					position = previousTime;
					previousTime = currentTime;
				}
			});
			player.on?.('seeking', () => {
				player.off('timeupdate', () => {});
				player.one('seeked', () => {});
				onSeeking?.(player.currentTime());
			});

			player.on?.('seeked', () => {
				const completeTime = Math.floor(player.currentTime());

				onSeeked?.(position, completeTime);
			});

			player.on?.('ended', () => {
				onEnd?.();
			});
		}, [onEnd, onError, onPause, onPlay, onReady, onSeeked, onSeeking, onTimeUpdate, player]);

		useEffect(() => {
			if (player.controlBar && active) {
				Object.keys(Controls).map((x) => {
					player?.controlBar[Controls[x]]?.show();
				});
				hideControls.map((x) => {
					player?.controlBar[Controls[x]]?.hide();
				});
			}
		}, [player?.controlBar, hideControls, active]);

		const id = useMemo(() => `video-player-${getRandomId()}`, []);

		const initPlayer = useCallback(
			(node) => {
				import(/* webpackChunkName: "VideoJs" */ 'video.js').then((videojs) => {
					const playerOptions = generatePlayerOptions();
					const play = videojs.default(node, playerOptions);

					if (src.includes('blob:') && props.mimeType) {
						play.src?.({ src, type: props.mimeType });
					} else {
						play.src?.(src);
					}

					if (props.poster) {
						play.poster?.(props.poster);
					}

					// eslint-disable-next-line no-underscore-dangle
					play.childNameIndex_?.ControlBar?.el_?.setAttribute('data-testid', 'videoControlBar');
					play.setAttribute('data-testid', 'videoPlayer');

					play.ready?.(() => {
						onReady?.(play);
						setPlayer(play);
					});

					//setControlsVisibility(hideControls);
				});
			},
			[generatePlayerOptions, onReady, props.mimeType, props.poster, src]
		);

		useEffect(() => {
			const videoSelector = document.querySelector(`#${id}`);

			if (videoSelector) {
				initPlayer(videoSelector);
			}
		}, [id, initPlayer]);

		useEffect(() => {
			if (!props.playing && player.pause) {
				player.pause();
			}
		}, [props.playing, player]);

		useEffect(
			() => () => {
				player?.dispose?.();
			},
			[player]
		);

		return (
			// eslint-disable-next-line jsx-a11y/media-has-caption
			<video
				id={id}
				className={`video-js ${props.bigPlayButtonCentered ? 'vjs-big-play-centered' : ''} ${
					props.className
				}`}
			>
				<track kind="captions" />
			</video>
		);
	}
);

VideoPlayer.propTypes = {
	src: PropTypes.string,
	poster: PropTypes.string,
	controls: PropTypes.bool,
	onError: PropTypes.func,
	autoplay: PropTypes.bool,
	captions: PropTypes.bool,
	mimeType: PropTypes.string,
	preload: PropTypes.oneOf(['auto', 'none', 'metadata']),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	hideControls: PropTypes.arrayOf(PropTypes.string),
	bigPlayButton: PropTypes.bool,
	bigPlayButtonCentered: PropTypes.bool,
	onReady: PropTypes.func,
	onPlay: PropTypes.func,
	onPause: PropTypes.func,
	onTimeUpdate: PropTypes.func,
	onSeeking: PropTypes.func,
	active: PropTypes.bool,
	onSeeked: PropTypes.func,
	onEnd: PropTypes.func,
	responsive: PropTypes.bool,
	playbackRates: PropTypes.arrayOf(PropTypes.number),
	hidePlaybackRates: PropTypes.bool,
	className: PropTypes.string,
	playing: PropTypes.bool,
};

VideoPlayer.defaultProps = {
	src: '',
	poster: '',
	controls: true,
	captions: true,
	mimeType: 'video/webm',
	autoplay: false,
	preload: 'auto',
	playbackRates: [0.5, 1, 1.5, 2],
	hidePlaybackRates: false,
	className: '',
	hideControls: [],
	responsive: false,
	playing: false,
	bigPlayButton: true,
	bigPlayButtonCentered: true,
	onReady: null,
	onPlay: null,
	onPause: null,
	onTimeUpdate: null,
	onSeeking: null,
	onSeeked: null,
	onEnd: null,
};

export default VideoPlayer;
