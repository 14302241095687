export const SECTION = 'SECTION';
export const QUESTION = 'QUESTION';

export const isSection = (val) => val === SECTION;
export const isQuestion = (val) => val === QUESTION;

export const COMPANY_NAME = 'ICIMS';

export const PRODUCT_DIFFERED = 'video_interview';
export const PRODUCT_LANGUAGE_ASSESSMENT = 'language_assessment';
export const PRODUCT_TESTBUILDER = 'testbuilder';

export const TEST_MY_VIDEO = 'TEST_MY_VIDEO';
export const TEST_TECH = 'TEST_TECH';
export const EXPLAIN = 'EXPLAIN';
export const TEST_WEBCAM = 'PRACTICE';
export const INTRO = 'INTRO';
export const OUTRO = 'OUTRO';
export const SECTIONS = 'EXAM';
export const SURVEY = 'SURVEY';
export const REQUISITES = 'REQUISITES';
export const BLOCKED = 'BLOCKED';
export const NOTINTERESTED = 'NOTINTERESTED';
export const TESTALREADYDONE = 'TESTALREADYDONE';
export const SESSIONERROR = 'SESSIONERROR';
export const WAITINGROOM = 'WAITINGROOM';

export const DBNAME = 'iCims Video Interview';

export const STORAGE_NO_SPACE = 'STORAGE_NO_SPACE';
export const STORAGE_DOES_NOT_EXIST = 'STORAGE_DOES_NOT_EXIST';

export const LIVE_RECORDER = 'live';
export const VIDEO_CAROUSEL_RECORDER = 'videoCarousel';

export const INDEXED_DB = {
	dataStoreName: 'interview video store',
};

export const errorPrivateNavigationFirefox =
	'A mutation operation was attempted on a database that did not allow mutations.';

export const LANGUAGE_ASSESSMENT_TYPE_REDACTION = 'redaction';
