import { rest } from 'msw';
import { navigation } from './workflow/template/sectionExemple';
import { getNavigationEndpoint } from '../utils/endpoint';
import { cookieServerName } from './utils';
let error = 0;

export default rest.get(getNavigationEndpoint, (req, res, ctx) => {
	console.info('get all template questions');
	let status = 200;
	if (error > 20) {
		status = 504;
	}
	error++;

	if (document.cookie.includes(cookieServerName)) {
		return res(
			ctx.delay(1500),
			ctx.status(status, 'Mocked status'),
			ctx.json(status === 504 ? { status } : navigation)
		);
	} else {
		return res(ctx.delay(500), ctx.status(401, 'Mocked Unauthorized'));
	}
});
