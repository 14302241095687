import { rest } from 'msw';
import { res } from './utils';
import { getLegalEndpoint } from '../utils/endpoint';

export default rest.get(getLegalEndpoint, (req, response, ctx) => {
	console.log(': legal called');
	return res(
		ctx.status(200),
		ctx.json({
			tc: '<p>Nunc tortor felis, aliquet bibendum varius ut, dictum eu arcu.</p><p>arius ut, dictum eu arcu.</p>',
			dpp: '<p>Nunc tortor fdsfsdfsdfsd fds fsd f dsf ds fdsfcu.</p><p>arius ufdsfds fds fds fd s fsd fdsu.</p>',
			legalDisclaimer: '<strong>agree with me !!!!!!</strong>fhjdjhfjksdfhsjkfhsdjk',
			tcDppOptin: '<strong>agree with me !!!!!!</strong>fhjdjhfjksdfhsjkfhsdjk',
			smartrankingOptin: '<strong>smart ranking!!!!!!</strong>fhjdjhfjksdfhsjkfhsdjk',
		})
	);
});
