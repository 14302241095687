import useFetch, { CachePolicies } from 'use-http';
import { useCallback } from 'react';

const useSWRFetcher = () => {
	const { get, response } = useFetch({
		cachePolicy: CachePolicies.NO_CACHE,
	});

	return useCallback(
		async (url) => {
			const res = await get(url);

			if (!response.ok) {
				const error = new Error('An error occurred while fetching the data.');

				error.message = res?.message ?? 'an error occured';

				error.status = res.status;
				throw error;
			}
			return res;
		},
		[response, get]
	);
};

export default useSWRFetcher;
