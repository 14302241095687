import useFetch, { CachePolicies, Provider } from 'use-http';
import { useState } from 'react';
import { node } from 'prop-types';
import { getProfilEndPoint, getSigninEndPoint } from '../../utils/endpoint';
import useLocalStorage from '../../hooks/useLocaleStorage';
import ErrorManager from '../shared/Error/ErrorManager';
import { captureException } from '@sentry/react';

const InitBackConnectionApp = ({ children }) => {
	const [error, setError] = useState(null);
	const {
		post,
		response: responseBack,
		patch,
	} = useFetch({
		cachePolicy: CachePolicies.NO_CACHE,
	});
	const [localToken] = useLocalStorage('localToken');
	const [email] = useLocalStorage('emailCandidate');
	const options = {
		onError: (err) => {
			if (err?.error?.name === 403) {
				return;
			}
			captureException(new Error(err.message ?? `${err.error.name} ${err.error.message}`));
		},
		headers: {
			'Content-Type': 'application/json',
		},
		cachePolicy: CachePolicies.NO_CACHE,
		retries: 3,
		timeout: 40000,
		onTimeout: () => {
			captureException(new Error('timeout on a query'));
		},
		onAbort: () => {
			captureException(new Error('Aborded by the user'));
		},
		retryOn: ({ err, response }) =>
			response?.status === 401 || response?.status > 500 || err?.name === 'TypeError',
		retryDelay: 1000,
		interceptors: {
			response: async ({ response }) => {
				const res = response;

				if (res.status === 404) {
					const endOfUrl = response.url.split('/').pop();

					if (endOfUrl === 'profile' || endOfUrl === 'campaign' || endOfUrl === 'signin') {
						setError({ status: res.status, message: 'INVALID_CAMPAIGN' });
					}
				}
				if (res.status === 401) {
					const endOfUrl = response.url.split('/').pop();

					if (endOfUrl === 'signin') {
						setError({
							status: res.status,
							message: 'INVALID_CAMPAIGN',
						});
						return res;
					}

					await post(getSigninEndPoint, {
						token: localToken,
					});
					if (email) {
						await patch(getProfilEndPoint, { email });
					}
					if (!responseBack.ok) {
						setError({ status: res.status, message: 'INVALID_CAMPAIGN' });
					}
				}
				if (res.status === 403) {
					const clonedResponse = res.clone();
					const responseForbidden = await clonedResponse.json();

					if (
						responseForbidden.message !== 'EXPIRED_INVITATION' &&
						responseForbidden.message !== 'EXPIRED_CAMPAIGN'
					) {
						captureException(new Error(responseForbidden.message));
					}
					if (responseForbidden.message === 'WRONG_STATUS') {
						// to refresh the actual status, because of a rescuer
						window.location.reload();
					}
					if (responseForbidden.message === 'SESSION_NOT_STARTED') {
						window.location.reload();
					}
					if (
						responseForbidden.message !== 'Step is locked' &&
						responseForbidden.message !== 'Forbidden'
					) {
						setError({
							status: res.status,
							message: responseForbidden.message,
						});
					}
					return res;
				}

				if (res.status === 500) {
					setError({
						status: res.status,
						message: 'SERVER_CONNECTION_ERROR',
					});
				}

				if (response.status === 412) {
					setError({
						status: res.status,
						message: 'TECHNICAL_ERROR',
					});
				}

				return res;
			},
		},
	};

	return <Provider options={options}>{error ? <ErrorManager error={error} /> : children}</Provider>;
};

InitBackConnectionApp.propTypes = {
	children: node,
};

export default InitBackConnectionApp;
