import { rest } from 'msw';

export default rest.get('/app-config.json', (req, res, ctx) => {
	console.info('finished the template');
	return res(
		ctx.delay(0),
		ctx.status(200, 'Mocked status'),
		ctx.json({ ENVARS: { VIDEO_RECORDER_ENDPOINT: null, VIDEO_RECORDER_CALLBACK_URL: null } })
	);
});
