import { rest } from "msw";
import { getExtendsEndPoint } from "../utils/endpoint";

export default rest.post(getExtendsEndPoint, (req, res, ctx) => {
  const { token, reason } = req.body;
  if (reason) {
    return res(ctx.status(204));
  }
  return res(ctx.status(401));
});
