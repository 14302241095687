import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { mixed } from 'yup';

const manageToast = (toasts, { title = 'info', action = 'add', message = 'test message' }) => {
	if (action === 'delete') {
		delete toasts[title];
	}
	if (action === 'add') {
		toasts[title] = { title, message };
	}
	return toasts;
};

export const initWorker = { status: 'idle', dataStoreName: null, wait: false, cursor: null };

/**
 *
 * @param modals
 * @param {string} id
 * @param {'show' | 'hide'} action
 * @param {Object} options
 * @returns {*}
 */

const manageModal = (modals, { id, action = 'show', options }) => {
	const schema = mixed().oneOf(['show', 'hide']);

	if (!schema.isValid) {
		throw new TypeError(`Modal cannot open with ${action}`);
	}
	if (!id) {
		throw new Error('unavailable Modal');
	}
	if (action === 'hide') {
		modals[id] = { ...modals[id], show: false };
	}

	if (action === 'show') {
		modals[id] = { id, show: true, ...options };
	}
	return modals;
};

/**
 *
 * @type {{app: PRODUCT_DIFFERED | PRODUCT_TESTBUILDER | PRODUCT_LANGUAGE_ASSESSMENT, toasts: Record<string,{title:string, message:string}>, practice: {needPractice: boolean, skip: boolean, modal: number}, workflow: []| null, splashScreenPrinted: boolean, consents: boolean, step: null, steps: null, modals: {}, errorDevice: boolean}}
 */
export const initialState = {
	app: null,
	// Actual step of the app (exemple : Practice)
	step: null,
	toasts: {},
	// Explain Steps
	steps: null,
	// when the splashscreen is already printed, the app wait x seconds before showing the splash screen again
	splashScreenPrinted: false,
	// Total workflow of the app
	workflow: null,
	// useful if public campaign
	consents: false,
	modals: {},
	practice: {
		// only for Practice Template
		// when true the practice is not displaying the tooltip and modals
		skip: false,
		// the modal to display on the beginning
		modal: 0,
		// useful for 'skip' button
		needPractice: false,
	},
	// when the webcam is on error, error device is usefull for Practice, VideoAnswer And TechnicalTEst to display the error
	errorDevice: false,
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		INIT_APP: (
			state,
			{ payload: { allSteps = state.workflow, actualStep, steps = state.steps, product } }
		) => {
			state.workflow = allSteps;
			state.steps = steps;
			state.app = product;
			if (actualStep === 'fromStart') {
				const [all] = allSteps;

				state.step = all;
			} else {
				const stepToFind = allSteps.find((step) => step.type === actualStep);

				if (!stepToFind) {
					// by default we tak e the first template
					const [all] = allSteps;

					state.step = all;
				} else {
					state.step = stepToFind;
				}
			}
		},
		INIT_STEPS: (state, { payload }) => {
			state.step = payload;
		},
		SPLASH_SCREEN_PRINTED: (state) => {
			state.splashScreenPrinted = true;
		},
		SET_CAMPAIGN: (state, { payload }) => (state.campaignToken = payload),
		SEND_CONSENTS: (state, { payload }) => {
			state.consents = payload;
		},
		REFRESH_SESSION_COUNTER: (state, { payload }) => {
			state.session = payload;
		},
		REFRESH_STEP: (state, { payload }) => {
			state.modals = {};
			state.toasts = {};
			state.step = payload;
		},
		HANDLE_MODAL: (state, { payload }) => {
			state.modals = manageModal(state.modals, payload);
		},
		HANDLE_TOAST: (state, { payload }) => {
			state.toasts = manageToast(state.toasts, payload);
		},
		CLOSE_ALL_TOAST: (state) => {
			state.toasts = {};
		},
		CLOSE_ALL_MODAL: (state) => {
			state.modals = {};
		},
		MAJ_PRACTICE: (state, { payload }) => {
			state.practice = { ...state.practice, ...payload };
		},
		MAJ_RTC: (state, { payload }) => {
			state.videoRTC = { ...state.videoRTC, ...payload };
		},
		MAJ_WORKER: (state, { payload }) => {
			state.worker = { ...state.worker, ...payload };
		},
		SET_SESSION_FINISHED: (state, { payload }) => {
			state.sessionFinished = payload;
		},
		I18N_INITIALIZED: (state, { payload }) => {
			state.i18n = payload;
		},
		DEVICES_AUTH: (state, { payload }) => {
			state.errorDevice = payload;
		},
		SET_PERMISSION_DEVICE: (state, { payload }) => {
			state.devices = payload;
		},
		SET_TECHNIQUE_OK: (state, { payload }) => {
			state.testTechnique = payload;
		},
	},
});

export const selectApp = (state) => state.app;
/**
 * Use it to refresh your component if one of the value of the state Change. Prefer using useSelectorQueryApp
 */
export const useSessionCounter = () => useSelector((state) => state.app.session);
/**
 * Use this to refresh only your component when only this var change
 * @param {string} query - query store to catch
 */
export const useSelectorQueryApp = (query) => useSelector((state) => state.app[query]);

/**
 *
 * @returns {typeof initWorker}
 */
export const useSelectorWorker = () => useSelector((state) => state.app.worker);
export default appSlice.reducer;
