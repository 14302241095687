export const colors = {
	white: '#fff',
	blue: '#07c',
	lightblue: '#3bb7e8',
	lightgray: '#F9F9FC',
	grayClassic: '#666d7c',
	gray: '#676767',
	gray2: '#666D7C',
	gray3: '#d9d9d9',
	gray4: '#9e9e9e',
	gray5: '#f8f8f8',
	green: '#72CC57',
	lightgreen: '#88a919',
	darkgreen: '#0c788b',
	black: '#010101',
	red: '#e24352',
	red2: '#e81940',
	red3: '#d83948',
};
export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64];
export const breakpointBootstrap = ['36em', '48em', '62em', '75em'];
export const buttonPrimary = {
	border: '1px solid #CCCCCC',
	color: colors.gray2,
	bg: colors.white,
};
