import { rest } from 'msw';
import { getFinishEndpoint } from '../utils/endpoint';
import data from './workflow/template/examMockConfig';
import { cookieServerName } from './utils';

export default rest.get(getFinishEndpoint, (req, res, ctx) => {
	console.info('finished the template');
	if (document.cookie.includes(cookieServerName)) {
		if (data.requisites) {
			global.profile.status = 'finished';
		}
		return res(ctx.delay(500), ctx.status(204, 'Mocked status'));
	} else {
		return res(ctx.delay(500), ctx.status(401, 'Mocked Unauthorized'));
	}
});
