import useSWR from 'swr';
import useSWRFetcher from '../hooks/useSWRFetcher';

const useConfigRemote = (auth = true) => {
	const get = useSWRFetcher();
	const { data, error, mutate, isValidating } = useSWR(auth ? '/api/v1/config' : null, get);

	const loading = !data && !error;

	return {
		data,
		error,
		loading,
		mutate,
		isValidating,
	};
};

export default useConfigRemote;
