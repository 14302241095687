import { SECTION } from '../../../utils/constants';
import examMockConfig from './examMockConfig';

const url1 =
	'https://static.qualify.talentplatform.eu/clients/1226_2822e7507d72e70d45b228fbf825087d/documents/20996test.mp4';
const url2 =
	'https://static.qualify.talentplatform.eu/clients/1226_2822e7507d72e70d45b228fbf825087d/documents/20996.mp4';
let error = 0;

const workflowSectionExemple = (video = false) => [
	{
		type: 'SECTION',
		name: 'section 1 - culture générale',
		sectionNumber: 1,
		splash: false,
		description:
			'Vous allez devoir répondre à une série de questions. Pour cela, nous vous invitons à vous mettre dans les meilleures dispositions',
	},
	{
		type: 'QUESTION',
		sectionNumber: 1,
		questionNumber: 1,
		questionId: '334',
		answer: {
			type: 'text',
			timeToAnswer: 200000,
			timeToRead: 30,
			timeFromAnswer: 5,
			minChar: 50,
		},
		candidateAnswer: null,

		name: 'Quel est votre date de naissance !???',
		back: {
			sectionNumber: 1,
			questionNumber: 0,
		},
	},
	{
		type: 'QUESTION',

		sectionNumber: 1,
		questionNumber: 2,
		name: 'En quelle année EASYRECRUE a été créé ?',
		medias: {
			video:
				'https://static.qualify.talentplatform.eu/clients/215_da529f7a00a67363fd60e7767ddaa094/documents/8562.mp4',
			document: {
				url: 'https://interview.qualify.talentplatform.eu/t/download_documents/k92193e33txd20210v7?q=80234',
				count: 2,
			},
			image:
				'https://static.qualify.talentplatform.eu/clients/215_da529f7a00a67363fd60e7767ddaa094/documents/8786.jpg',
		},

		back: {
			sectionNumber: 1,
			questionNumber: 1,
		},
		answer: {
			timeToAnswer: 10,
			type: 'choice',
			choices: [
				{ choiceNumber: '0', name: '2010' },
				{ choiceNumber: '1', name: '2014' },
				{ choiceNumber: '2', name: '2015' },
			],
			maxChoices: 3,
		},
		candidateAnswer: null,
	},
	{
		type: 'QUESTION',
		sectionNumber: 1,
		questionNumber: 3,
		answer: {
			type: 'document',
			authorizedExtensions: ['pdf', 'doc', 'jpg', 'png'],
			maxFileSize: 1000000,
		},
		candidateAnswer: {
			file: {
				mimeType: 'pdf',
				name: 'test',
				size: 2389893,
			},
		},
		medias: {
			document: {
				url: 'https://interview.qualify.talentplatform.eu/t/download_documents/k92193e33txd20210v7?q=80234',
				count: 2,
			},
		},
		name: 'Veuillez charger votre CV',

		back: {
			sectionNumber: 1,
			questionNumber: 2,
		},
	},
	{
		type: 'QUESTION',
		sectionNumber: 1,
		questionNumber: 4,
		name: "Qui est le CEO d'EASYRECRUE ?",
		medias: {
			image:
				'https://www.pixel4k.com/wp-content/uploads/2019/03/spiderman-miles-lost-in-space-4k_1553071367.jpg',
		},
		back: {
			sectionNumber: 1,
			questionNumber: 3,
		},
		answer: {
			timeToAnswer: 10,
			type: 'text',
		},
		candidateAnswer: null,
	},
	{
		type: 'QUESTION',
		sectionNumber: 1,
		questionNumber: 5,
		title: 'Question vidéo pre-rec',
		name: 'Quelles sont tes qualités?',

		back: {
			sectionNumber: 1,
			questionNumber: 4,
		},
		answer: {
			timeToRead: 30,
			timeFromAnswer: 10,
			timeToAnswer: 60,
			type: 'text',
			minChar: 100,
			choices: [
				{ choiceNumber: 0, name: 'détermination' },
				{ choiceNumber: 1, name: 'motivation' },
				{ choiceNumber: 2, name: 'excellence' },
			],
			maxChoices: 1,
		},
		candidateAnswer: { choices: [2] },
	},
	...(video
		? [
				{
					type: 'QUESTION',
					sectionNumber: 1,
					questionId: 386,
					questionNumber: 6,
					answer: {
						type: 'video',
						timeToAnswer: 120,
						timeToRead: 30,
						timeFromAnswer: 10,
					},
					medias: {
						image:
							'https://static.staging.easyrecrue.tech/clients/102_49e64a808bcc22fe1ff9e19fbd8be33c/documents/461.jpg',
						document: {
							url: 'https://i0.wp.com/www.nana-turopathe.com/wp-content/uploads/2015/07/soleil-e1435759702694.png?resize=1000%2C675&ssl=1',
							count: 2,
						},
					},
					name: "Et vous qu'attendez vous de la gestion de la crise ?'",
					back: {
						sectionNumber: 1,
						questionNumber: 5,
					},
					candidateAnswer: null,
				},
				{
					type: 'QUESTION',
					sectionNumber: 1,
					questionNumber: 7,
					questionId: 314,
					answer: {
						type: 'video',
						timeToAnswer: 110,
						timeToRead: 30,
						timeFromAnswer: 10,
					},
					name: 'Question video 2 just to try',
					back: {
						sectionNumber: 1,
						questionNumber: 6,
					},
					candidateAnswer: {
						url1: 'https://static.staging.easyrecrue.tech/clients/102_49e64a808bcc22fe1ff9e19fbd8be33c/tbcampaigns/35_65a4df4eae55693142dffcfc2dae3300/tbcandidates/507_e6385f7294ac4bb8ceaff30fb644d0ab/videos/507-0.mp4?t=1605274084',
					},
				},
		  ]
		: []),
	{
		type: 'SECTION',
		name: 'section 2 - autres sections',
		sectionNumber: 2,
		back: {
			sectionNumber: 1,
			questionNumber: 5,
		},
		questionNumber: 0,
		description: 'Vous allez devoir répondre à une question video et une texte',
	},
	{
		type: 'QUESTION',
		title: 'Question text',
		sectionNumber: 2,
		questionNumber: 1,
		splash: true,
		back: {
			sectionNumber: 2,
			questionNumber: 0,
		},

		name: 'Quelles est ton nombre préféré? Quelles est ton nombre préféré? Quelles est ton nombre préféré?Quelles est ton nombre préféré? Quelles est ton nombre préféré? Quelles est ton nombre préféré? Quelles est ton nombre préféré? Quelles est ton nombre préféré? Quelles est ton nombre préféré? Quelles est ton nombre préféré? Quelles est ton nombre préféré?  Quelles est ton nombre préféré?',

		answer: {
			type: 'choice',
			choices: [
				{ choiceNumber: 0, name: '1fds' },
				{ choiceNumber: 1, name: '2fds' },
				{ choiceNumber: 2, name: '3fds' },
			],
			maxChoices: 1,
		},
		candidateAnswer: null,
	},
	{
		type: 'QUESTION',
		sectionNumber: 2,
		questionNumber: 2,
		lastStep: true,

		name: 'Et toi, quel dev est tu?',
		medias: {
			video:
				'https://static.qualify.talentplatform.eu/clients/1226_2822e7507d72e70d45b228fbf825087d/documents/20996test.mp4',
		},

		answer: {
			type: 'text',
			timeToRead: 30,
			timeFromAnswer: 10,
			timeToAnswer: 10,
		},
		back: {
			sectionNumber: 2,
			questionNumber: 1,
		},
		candidateAnswer: null,
	},
];

export const navigation = workflowSectionExemple(examMockConfig.hasVideoAnswers).map(
	({ type, sectionNumber, questionNumber, name, answer, readonly, description }) => {
		return {
			type,
			sectionNumber,
			questionNumber,
			readonly,
			name,
			...(type === 'QUESTION' && { answered: false }),
			...(type === 'QUESTION' && { answerType: answer.type }),
			description,
		};
	}
);
export default workflowSectionExemple(examMockConfig.hasVideoAnswers);
