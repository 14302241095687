import { rest } from 'msw';
import { getFinishSurveyEndpoint, getSurveyEndpoint } from '../utils/endpoint';

const survey = {
	questions: [
		{
			name: 'first_time',
			mandatory: true,
			type: 'radio',
			answers: ['yes', 'no'],
		},
		{
			type: 'scale',
			name: 'like_interview',
		},
		{
			type: 'radio',
			name: 'interview_advantage',
			mandatory: true,
			answers: ['not_to_move', 'stand_out', 'when_we_want', 'new_solution', 'no_advantage'],
		},
		{
			type: 'scale',
			name: 'opinion_company',
		},
		{
			type: 'scale',
			name: 'easy_to_use',
		},
		{
			type: 'radio',
			name: 'interview_again',
			answers: ['yes_absolutely', 'not_too_much'],
		},
		{
			type: 'text',
			name: 'any_comment',
		},
	],
};

export default rest.get(getSurveyEndpoint, (req, res, ctx) => {
	return res(ctx.status(200, 'Mocked unauthorized'), ctx.json(survey), ctx.delay(1000));
});

export const postSurvey = rest.post(getFinishSurveyEndpoint, (req, res, ctx) => {
	return res(ctx.status(204, 'Mocked Authorized'), ctx.delay(1000));
});
