import { rest } from 'msw';
import { cookieServerName, res, getToken, getFileSection } from './utils';
import { getSectionsEndpoint, getCurrentQuestionsEndPoint } from '../utils/endpoint';
import JSON from './document.json';
import daysjs from 'dayjs';

let step = -1;
let error = 1;
let timerIsLaunched = false;
let sectionStart = false;

// get current question
export const current = rest.get(getCurrentQuestionsEndPoint, async (req, res, ctx) => {
	const token = getToken();
	const file = await getFileSection(token);
	const { default: sectionDefault } = file;

	if (profile.status !== 'started') {
		profile.status = 'started';
	}
	const alreadyStarted = localStorage.testStarted === 'true';
	if (alreadyStarted && !global.campaign.freeNavigation) {
		//step++;
	}

	if (!timerIsLaunched && global.campaign.globalTimer) {
		timerIsLaunched = true;
		global.profile.timerEnd = 40;
	}

	// test session ended
	if (daysjs().unix() > global.campaign.session?.endDate) {
		return res(ctx.delay(400), ctx.status(403), ctx.json({ error: 403, message: 'SESSION_ENDED' }));
	}

	// test session Locked
	if (
		!sectionStart &&
		global.campaign.session?.timeToAccess > 0 &&
		global.campaign.session?.startDate + global.campaign.session?.timeToAccess * 60 <
			daysjs().unix()
	) {
		return res(
			ctx.delay(400),
			ctx.status(403),
			ctx.json({ error: 403, message: 'SESSION_LOCKED' })
		);
	}

	sectionStart = true;
	let statusCode = 200;
	if (error > 1 && error < 3) {
		// to test the refresh token
		statusCode = 401;
	} else {
		step++;
	}

	error++;
	return res(
		ctx.delay(400),
		ctx.status(statusCode, 'Mocked status'),
		ctx.json(statusCode === 200 ? sectionDefault[step] : { message: 'Forbidden' })
	);
});

export const postQuestion = rest.post(getSectionsEndpoint, async (req, response, ctx) => {
	const token = getToken();
	let file = await getFileSection(token);

	const { navigation, default: sectionDefault } = file;
	const key = Object.keys(req.body).find((e) => e === 'value' || e === 'choices' || e === 'file');

	// If practice
	if (req.body.sectionNumber === 0 && req.body.questionNumber === 0) {
		return res(ctx.status(204, 'Mocked status'));
	}

	// we do not record candidate answer for video
	if (sectionDefault[step].answer?.type !== 'video') {
		sectionDefault[step].candidateAnswer = { [key]: req.body[key] };
	} else {
		// if video answer
		sectionDefault[step].candidateAnswer = {
			url: 'https://static.staging.easyrecrue.tech/clients/102_49e64a808bcc22fe1ff9e19fbd8be33c/tbcampaigns/35_65a4df4eae55693142dffcfc2dae3300/tbcandidates/507_e6385f7294ac4bb8ceaff30fb644d0ab/videos/507-0.mp4?t=1605274084',
		};
	}

	// if document answer
	if (req.body.file) {
		if (!Object.keys(req.body.file).length) {
			sectionDefault[step].candidateAnswer = null;
		} else {
			delete sectionDefault[step].candidateAnswer.file.b64;
		}
	}
	if (req.body.questionNumber) {
		navigation[step].answered = true;
	}
	let statusCode = 204;

	return response(ctx.delay(50000), ctx.status(statusCode, 'Mocked status'));
});

// navigate within questions
export default rest.get(
	getSectionsEndpoint + `/:sectionNumber/:questionNumber`,
	async (req, res, ctx) => {
		const token = getToken();
		const file = await getFileSection(token);
		const { default: sectionDefault } = file;
		const numberizeSectionNumber = parseInt(req.params.sectionNumber, 10);
		const numberizeQuestionNumber = parseInt(req.params.questionNumber, 10);
		console.log('sectionDefaut: ', sectionDefault);
		const actual = sectionDefault.find((elem) => {
			return (
				elem.sectionNumber === numberizeSectionNumber &&
				(elem.questionNumber ?? 0) === numberizeQuestionNumber
			);
		});
		const actualIndex = sectionDefault.indexOf(actual);
		step = actualIndex;

		// to simulate rescuer
		/*if (req.params.sectionNumber === "1" && req.params.questionNumber === "2") {
      global.profile.status = "invited";
      step = -1;
      return res(
        ctx.delay(400),
        ctx.status(403),
        ctx.json({ message: "WRONG_STATUS" })
      );
    }*/
		let status = 200;
		if (error > 3 && error <= 5) status = 401;
		error++;
		if (actual) {
			return res(ctx.delay(400), ctx.status(status), ctx.json(actual));
		}
		return res(ctx.status(404));
	}
);

export const getDocument = rest.get(
	getSectionsEndpoint + `/:sectionNumber/:questionNumber/document`,
	(req, res, ctx) => {
		console.info('update profile');
		const numberizeSectionNumber = parseInt(req.params.sectionNumber, 10);
		const numberizeQuestionNumber = parseInt(req.params.questionNumber, 10);
		const testResponseStatus = 204;
		if (numberizeSectionNumber && numberizeQuestionNumber) {
			if (document.cookie.includes(cookieServerName)) {
				return res(ctx.delay(500), ctx.status(200, 'Mocked status'), ctx.json(JSON));
			} else {
				return res(ctx.delay(500), ctx.status(401, 'Mocked Unauthorized'));
			}
		}
		return res(ctx.delay(500), ctx.status(401, 'Mocked Unauthorized'));
	}
);
