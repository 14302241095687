import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Router } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { PersistGate } from 'redux-persist/integration/react';
import { Integrations } from '@sentry/tracing';
import BootstrapApp from './components/EntryPoint';
import { ThemeProvider } from 'styled-components';
import theme from './theme/theme';
import InitBackConnectionApp from './components/Root/InitBackConnection';
import { SWRConfigValue } from './utils/config';
import { store, persistor } from './store/store';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import DelayedFallback from './components/shared/SplashScreen';
// eslint-disable-next-line no-undef

const history = createBrowserHistory();

const [, , token] = history.location.pathname.split('/', 3);

const accessOk = ['testbuilder', 'language_assessment', 'interview', 'template'];

if ((token && /\/[^&\n]*\/ASSESS-/.test(history.location.pathname)) || accessOk.includes(token)) {
	require('./mocks');
}

// if (process.env.NODE_ENV === 'production') {
// 	// eslint-disable-next-line no-undef
// 	import('@sentry/react').then((Sentry) =>
// 		Sentry.init({
// 			// eslint-disable-next-line no-undef
// 			environment: process.env.REACT_APP_VERSION,
// 			release: process.env.npm_package_version,
// 			ignoreErrors: ['ResizeObserver loop limit exceeded'],
// 			dsn: 'https://dce931dd0a754e5cae74a2c5b10d8ec3@o268619.ingest.sentry.io/5494437',
// 			integrations: [
// 				new Integrations.BrowserTracing({
// 					// Can also use reactRouterV4Instrumentation
// 					routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
// 				}),
// 			],
//
// 			// We recommend adjusting this value in production, or using tracesSampler
// 			// for finer control
// 			tracesSampleRate: 1.0,
// 		})
// 	);
// 	const trackingId = 'G-N4HP22C7F5';
//
// 	import('react-ga').then((ReactGA) => ReactGA.initialize(trackingId));
// }

// https://github.com/getsentry/sentry-react-native/issues/794

const container = document.getElementById('root');

ReactDOM.render(
	<React.StrictMode>
		<Router history={history}>
			<SWRConfig value={SWRConfigValue}>
				<ThemeProvider theme={theme}>
					<Suspense fallback={<DelayedFallback />}>
						<Provider store={store}>
							<PersistGate loading={null} persistor={persistor}>
								<InitBackConnectionApp>
									<BootstrapApp />
								</InitBackConnectionApp>
							</PersistGate>
						</Provider>
					</Suspense>
				</ThemeProvider>
			</SWRConfig>
		</Router>
	</React.StrictMode>,
	container
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
