import { response, context } from 'msw';

export const cookieServerName = 'MOCKTOKEN';

export const initDelay =
	(delay = 0) =>
	(...transformers) => {
		return response(context.delay(delay), ...transformers);
	};

export const getToken = () => {
	let token;
	try {
		token = JSON.parse(localStorage.getItem('localToken'));
	} catch (err) {
		return 'template';
	}
	return token;
};

export const res = initDelay(100);

export const getFileSection = async (token) => {
	let file;
	try {
		file = await import(
			`./workflow/${token.startsWith('ASSESS') ? 'ASSESS/' + token : token}/sectionExemple`
		);
	} catch (err) {
		file = await import(`./workflow/${global.campaign.product ?? 'template'}/sectionExemple`);
	}
	return file;
};

export function merge(target, source) {
	var fixedKeys = ['campaign', 'profile'];

	Object.entries(source).forEach(([key, value]) => {
		if (value && value.toString() === '[object Object]') {
			merge((target[key] = target[key] || {}), value);
			return;
		}
		if (value && Array.isArray(value)) {
			merge((target[key] = target[key] || []), value);
			return;
		}
		if (fixedKeys.indexOf(key) !== -1) {
			target[key] = value;
			return;
		}
		target[key] = target[key] || {};
		target[key] = value;
	});
	return target;
}

export const updateDataByParams = (hasCampaignParams) => {
	if (Object.keys(hasCampaignParams).length) {
		const values = Object.entries(hasCampaignParams).map(([key, val]) => {
			const cascadingObject = key.split('.');

			return cascadingObject.reduceRight((acc, item, i) => {
				if (i === cascadingObject.length - 1) {
					let parseVal;
					try {
						parseVal = JSON.parse(val);
					} catch (err) {
						parseVal = val;
					}

					return { [item]: parseVal };
				}
				return { [item]: acc };
			}, {});
		});
		return values.reduce(merge, {});
	}
};
