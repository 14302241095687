import styled, { css } from 'styled-components';
import VideoPlayer from './VideoJsWrapper';
import { hexToRgbA } from '../../utils/tools';
import { string } from 'prop-types';

const listControls = ['play', 'volume', 'seekbar', 'timer', 'playbackrates', 'fullscreen', 'pip'];

const VideoStyled = styled(VideoPlayer)`
	width: 100%;
	height: ${({ height }) => height};
	padding: 28%;
	${({ theme }) => css`
		${theme.mediaQueries.large} {
			padding: 32%;
		}
	`};
	border-radius: ${({ theme }) => theme.image.borderRadius};
	&:hover {
		.vjs-big-play-button {
			box-shadow: 0 0 3em #fff;
			background-color: ${({ bg }) => bg};
			border: 9px solid ${({ bg }) => hexToRgbA(bg, 0.4)};
		}
	}
	.vjs-big-play-button {
		z-index: 2;
		border-radius: 70px;
		width: 70px;
		height: 70px;
		background-color: ${({ bg }) => bg};
		border: 9px solid ${({ bg }) => hexToRgbA(bg, 0.4)};
		cursor: pointer;
		background-clip: content-box;
		opacity: 1;
		transform: translate(-50%, -50%);
		margin-top: 0;
		margin-left: 0;
		box-shadow: 0 0 1em rgba(255, 255, 255, 0.25);
		transition: all 0.4s;
	}

	.vjs-icon-placeholder:before {
		transform: translate(3%, 7%);
	}

	.vjs-tech {
		${({ hideControls }) =>
			hideControls.length === listControls.length &&
			css`
				pointer-events: none;
			`};
	}

	.vjs-poster {
		border-radius: ${({ theme }) => theme.image.borderRadius};
	}
	video {
		border-radius: ${({ theme }) => theme.image.borderRadius};
	}
	.vjs-control-bar {
		${({ hideControls }) =>
			hideControls.length === listControls.length &&
			css`
				display: none;
			`};
		border-radius: ${({ theme }) => theme.image.borderRadius};
	}
`;

const VideoJs = ({ height = '100%', ...props }) => <VideoStyled height={height} {...props} />;

VideoJs.propTypes = {
	height: string,
};

export default VideoJs;
