import { rest } from 'msw';
import { getMediaRecorderChunk, getMediaRecorderEndpoint } from '../utils/endpoint';

let error = 0;

export const mediaChunk = rest.post(
	`https://recorder.staging.easyrecrue.tech/chunks`,
	(req, res, ctx) => {
		let status = 204;
		error++;
		if (error >= 3 && error < 5) {
			status = 401;
		}

		return res(ctx.status(status));
	}
);

export const mediaClose = rest.post(
	`https://recorder.staging.easyrecrue.tech/api/v1/records/:token/close`,
	(req, res, ctx) => {
		const { token, reason } = req.body;

		let status = 204;
		error++;
		if (error >= 1 && error <= 2) {
			return res(
				ctx.status(422),
				ctx.json({
					code: 422,
					message: '[Record] invalid chunks number',
					properties: {
						missings: [2],
					},
				})
			);
		}

		return res(ctx.status(204));
	}
);
