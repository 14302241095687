import { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import useEventListener from './useEventListener';

/**
 *
 * @param {number} typeDevice - 0 is a mobile, 1 a medium device ...
 * @return {boolean}
 */
const useMediaQueryDevice = (typeDevice = 0) => {
	const theme = useTheme();
	const [windowSize, setWindowSize] = useState(window.innerWidth);

	const isSmallDevice = useMemo(() => {
		if (windowSize) {
			return !window.matchMedia(`(min-width:${theme.breakpoints[typeDevice]})`).matches;
		}
		return false;
	}, [theme.breakpoints, typeDevice, windowSize]);

	useEventListener('resize', () => setWindowSize(window.innerWidth));

	return isSmallDevice;
};

export default useMediaQueryDevice;
