import { rest } from "msw";
import { getExtentionStatus } from "../utils/endpoint";
import { cookieServerName } from "./utils";

export default rest.get(getExtentionStatus, (req, res, ctx) => {
  console.info("ask extention status");
  if (document.cookie.includes(cookieServerName)) {
    return res(
      ctx.delay(500),
      ctx.status(200, "Mocked status"),
      ctx.json({ extensionStatus: "none", extensionReason: null })
    );
  } else {
    return res(ctx.delay(500), ctx.status(401, "Mocked Unauthorized"));
  }
});
