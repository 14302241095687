import useSWR from 'swr';
import { getAllStepsEndPoint, getPreviewEndPoint } from '../utils/endpoint';
import useFetch, { CachePolicies } from 'use-http';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

/**
 * @typedef {Object} Landing
 * @property {"fullscreen"|"default"|"background"|"carousel"|"gallery"} template
 * @property {string} introText
 * @property {any[]} medias
 */

/**
 * @typedef {Object} Legal - Legal Information you need to display the Legal Modal
 * @property {boolean} display - do you must display the legal modal ?
 * @property {boolean} customized - is this legacy mode or the customised mode ?
 */

/**
 * @typedef {Object} CampaignData
 * @property {string} clientName -how the recruiter is called
 * @property {string} logo - logo of the client
 * @property {number} mobileMode - is tHe app could be display on mobile?
 * @property {'easyspeaking' | 'live' | 'testbuilder' | 'planner'} product - test builder, language ...
 * @property {Recruiter} recruiter
 * @property {number} minBandwidthUp - minimum connection that the candidate must have
 * @property {Object} proctoring
 * @property {number} globalTimer
 * @property {{audio:string, video:string}} tutorialUrls
 * @property {number} clientId
 * @property {boolean} proctoring.randomPictures - screenshot of the webcam
 * @property {boolean} proctoring.blockCopyPaste - during examination cannot copy paste on text answer
 * @property {Styles} styles - custom client styles of the campaign
 * @property {string} locale -custom language of the client
 * @property {Landing} landing
 * @property {boolean} reRecording - if carousel needs to be activated and the videos deferred.
 * @property {boolean} freeNavigation - could go back during the exam.
 * @property {Requisite} requisites - at the end of the exam, do we need some document ?
 * @property {UtilsCampaign} utils
 * @property {string} mobileURLRedirection
 * @property {boolean} training - campaign that do not save the video on the server
 * @property {boolean} survey - survey at the end of the exam (after outro)
 * @property {Legal} legal
 * @property {boolean} smartranking - legacy - usefull for the legacy legal template
 * @property {boolean} hasVideoAnswers - the exam need the webcam
 * @property {Session | null} session
 * @property {Object} outro - the end's content
 * @property {string} supportEmail
 * @property {string} supportChatScript
 * @property {boolean} launchDarklyCheck - check soft maintenance page status on LaunchDarkly
 * @property {string} launchDarklyClientSideKey - LaunchDarkly client side key
 * @property {string} nextMaintenanceEndTime
 * @property {string} supportPhone
 * @property {string} maintenanceDate
 * @property {string} maintenanceHour
 * @property {boolean} hasCertificate
 */

/**
 * @typedef {Object} Recruiter
 * @property {string} name
 * @property {string} title
 * @property {string} avatar
 */

/**
 * @typedef {Object} UtilsCampaign
 * @property {boolean} isSessionCampaign
 */

/**
 * Hooks to fetch data from the campaign
 * @param {boolean} auth
 * @returns {{data: CampaignData, error: any}}
 */
const useCampaignData = (auth = true) => {
	const { get } = useFetch({
		cachePolicy: CachePolicies.NO_CACHE,
	});
	const { token } = useParams();

	let location = useLocation();

	const getUrl = location.pathname.includes('/preview/')
		? `${getPreviewEndPoint}/${token}`
		: getAllStepsEndPoint;

	const { data, error, mutate } = useSWR(auth ? getUrl : null, get);

	return {
		data: useMemo(() => {
			if (!data) {
				return undefined;
			}

			const utils = {
				isSessionCampaign: data && Boolean(data.session?.startDate),
			};

			return { ...data, utils };
		}, [data]),
		error,
		mutate,
	};
};

export default useCampaignData;
