import { rest } from 'msw';
import { getSigninEndPoint } from '../utils/endpoint';
import { cookieServerName, merge, updateDataByParams } from './utils';

const token = 'mockTokenAPI';
const issueInterview = 'ygissue';
const invalidToken = 'yginvalid';
const tokenNotInterested = 'tokenNotInterested';
const alreadyUsedToken = 'ygiused';
export const sessionExpired = 'sessionExpired';
const tokenJoined = 'tokenJoined';
export const invalidCampaignToken = 'invalidCampaignToken';

export default rest.post(getSigninEndPoint, (req, res, ctx) => {
	console.info('sending questions answer', Object.fromEntries(req.url.searchParams));

	if (req?.body?.token === sessionExpired) {
		return res(
			ctx.delay(500),
			ctx.cookie(cookieServerName, sessionExpired, {
				path: '/',
			}),
			ctx.cookie('joined', req?.body?.token === tokenJoined),

			ctx.cookie(
				'cookieASSESS',
				/\/[^&\n]*\/ASSESS-/.test(req?.body?.token) ? req?.body?.token : null
			),
			ctx.status(204, 'Mocked Auth Ok')
		);
	}

	if (
		req?.body.token.startsWith('ASSESS-') ||
		req?.body.token === 'testbuilder' ||
		req.body.token === 'language_assessment' ||
		req.body.token === 'interview' ||
		req.body.token === 'template' ||
		req?.body?.token === token ||
		req?.body?.token === tokenNotInterested ||
		req?.body?.token === tokenJoined ||
		req?.body?.token === sessionExpired ||
		req?.body?.token === invalidCampaignToken
	) {
		const hasCampaignParams = Object.fromEntries(req.url.searchParams);
		const data = updateDataByParams(hasCampaignParams);
		if (data) {
			global.campaign = data.campaign;
			global.profile = data.profile;
		}

		return res(
			ctx.delay(500),
			ctx.cookie('searchParamsMock', Boolean(hasCampaignParams)),
			ctx.cookie(cookieServerName, req.body?.token, {
				path: '/',
			}),
			ctx.cookie('joined', req.body.token === tokenJoined),

			ctx.status(204, 'Mocked Auth Ok')
		);
	}
	if (req?.body?.token === issueInterview) {
		return res(
			ctx.delay(500),
			ctx.status(401, 'Mocked Unauthorized'),
			ctx.json({
				status: 'error',
				message: 'Code valid but interview not available right now',
			})
		);
	}

	if (req?.body?.token === alreadyUsedToken) {
		return res(
			ctx.delay(500),
			ctx.status(401, 'Mocked Unauthorized'),
			ctx.json({
				status: 'error',
				message: 'token already used',
			})
		);
	}

	if (req?.body?.token === invalidToken) {
		return res(
			ctx.delay(500),
			ctx.status(401, 'Mocked Unauthorized'),
			ctx.json({
				status: 'error',
				message: 'Invalid token',
			})
		);
	} else {
		return res(
			ctx.delay(500),
			ctx.status(401, 'Mocked Unauthorized'),
			ctx.json({
				status: 'error',
				message: 'Invalid token',
			})
		);
	}
});
