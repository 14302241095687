import styled from 'styled-components';
import {
	space,
	layout,
	color,
	typography,
	variant,
	position,
	system,
	compose,
} from 'styled-system';

const Span = styled.span`
	:focus-visible {
		transition: all 0.5s;
		font-weight: bolder;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.25rem rgb(0 123 255 / 25%);
	}
	${compose(space, layout, color, typography, position, system({ cursor: true }))}

	${({ theme }) =>
		variant({
			variants: {
				error: { color: theme.colors.red2 },
			},
		})}
`;

export default Span;
