import PropTypes from 'prop-types';
import { color } from 'react-extra-prop-types';
import { QUESTION, SECTION } from '../utils/constants';

export const stylesProps = PropTypes.shape({
	bannerBackground: color,
	buttonBackground: color,
	bannerText: color,
	title: color,
	buttonText: color,
	campaignName: color,
});

export const stepProps = PropTypes.shape({
	type: PropTypes.string,
	video: PropTypes.bool,
	proctoring: PropTypes.bool,
	label: PropTypes.string,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
});

export const stepsProps = PropTypes.arrayOf(stepProps);

export const mediaProps = PropTypes.shape({
	category: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	url: PropTypes.string,
	mimeType: PropTypes.string,
	poster: PropTypes.string,
	node: PropTypes.shape(),
});

export const mediasProps = PropTypes.arrayOf(mediaProps);
export const templateProps = PropTypes.oneOf([
	'fullscreen',
	'textOnly',
	'default',
	'background',
	'gallery',
	'carousel',
]);

export const landingProps = {
	isSessionCampaign: PropTypes.bool,
	template: templateProps,
	handleShow: PropTypes.func,
	as: PropTypes.string,
	styles: stylesProps.isRequired,
	medias: mediasProps,
	introText: PropTypes.string.isRequired,
};

export const sessionProps = PropTypes.shape({
	timeToAccess: PropTypes.number,
	startDate: PropTypes.number,
	endDate: PropTypes.number,
});

export const recruiterProps = PropTypes.shape({
	name: PropTypes.string,
	title: PropTypes.string,
	avatar: PropTypes.string,
});

export const campaignProps = PropTypes.shape({
	styles: stylesProps.isRequired,
	clientName: PropTypes.string.isRequired,
	logo: PropTypes.string,
	recruiter: recruiterProps.isRequired,
	medias: mediasProps,
	mobileMode: PropTypes.bool.isRequired,
	session: sessionProps,
});

export const paddingProps = PropTypes.oneOfType([
	PropTypes.arrayOf(PropTypes.string),
	PropTypes.string,
]);

export const answerMultipleProps = {
	maxChoices: PropTypes.number,
	choices: PropTypes.arrayOf(
		PropTypes.shape({
			choiceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			name: PropTypes.string,
		})
	),
};

export const answerDocumentProps = {
	authorizedExtensions: PropTypes.arrayOf(PropTypes.string),
	maxFileSize: PropTypes.number,
};

export const answerProps = {
	type: PropTypes.oneOf(['document', 'choice', 'text', 'date', 'video']).isRequired,
	timeToAnswer: PropTypes.number,
	timeFromAnswer: PropTypes.number,
	timeToRead: PropTypes.number,
	minChar: PropTypes.number,
};

export const mediasQuestionProps = PropTypes.shape({
	video: PropTypes.string,
	image: PropTypes.string,
	document: PropTypes.shape({
		url: PropTypes.string,
		count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	}),
});

export const backAnswerProps = PropTypes.shape({
	sectionNumber: PropTypes.number.isRequired,
	questionNumber: PropTypes.number,
});

export const questionProps = {
	readonly: PropTypes.bool,
	type: PropTypes.oneOf([QUESTION, SECTION]),
	candidateAnswer: PropTypes.any,
	description: PropTypes.string,
	sectionNumber: PropTypes.number.isRequired,
	questionNumber: PropTypes.number,
	answer: PropTypes.shape({
		...answerProps,
		...answerMultipleProps,
		...answerDocumentProps,
	}),
	name: PropTypes.string.isRequired,
	medias: mediasQuestionProps,
	back: backAnswerProps,
};

export const currentQuestionProps = PropTypes.shape(questionProps);
