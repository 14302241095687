import { useTheme } from 'styled-components';
import { Img, Box } from '../../../../library';
import { mediaProps } from '../../../../types/propTypes';
import { string, bool, func } from 'prop-types';
import { color } from 'react-extra-prop-types';
import { memo, useMemo } from 'react';
import VideoStyled from '../../../../library/Video/VideoJs';
import useCampaignData from '../../../../data/useCampaignData';

const VideoOrImageTpl = memo(
	({
		media,
		height = '100%',
		onPause,
		onPlay,
		playing,
		onError,
		responsive,
		active = false,
		...props
	}) => {
		const theme = useTheme();
		const {
			data: { styles },
		} = useCampaignData();

		const hideControls = useMemo(() => ['playbackrates'], []);

		return (
			<>
				{media.type === 'video' && (
					<VideoStyled
						data-testid="video-player"
						active={active}
						bg={styles.buttonBackground}
						height={height}
						bigPlayButton={media.bigPlayButton}
						responsive={responsive}
						onPlay={onPlay}
						onError={onError}
						hideControls={hideControls}
						onPause={onPause}
						playing={playing || Boolean(media.poster)}
						src={media.url}
						mimeType={media.mimeType}
						poster={media.poster}
						{...props}
					/>
				)}
				{media.type === 'image' && (
					<Img
						data-testid="media-image"
						borderRadius={theme.image.borderRadius}
						width="100%"
						src={media.url}
						alt={`${media.type} ${media.category}`}
						{...props}
					/>
				)}
				{media.type === 'bloc' && (
					<Box data-testid="media-bloc" height={['170px', '300px']} {...props}>
						<media.node
							onKeyUp={(e) => e.keyCode === 13 && media.onClick}
							onClick={() => media.onClick}
						/>
					</Box>
				)}
			</>
		);
	}
);

VideoOrImageTpl.propTypes = {
	media: mediaProps,
	height: string,
	playing: bool,
	onPause: func,
	onError: func,
	bigPlayButton: bool,
	playColor: color,
	active: bool,
	onPlay: func,
	responsive: bool,
};

export default memo(VideoOrImageTpl);
