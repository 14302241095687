// fullscreen img https://static.qualify.talentplatform.eu/clients/215_da529f7a00a67363fd60e7767ddaa094/321_image-transparente.png
//fulscreen logo : https://static.qualify.talentplatform.eu/clients/215_da529f7a00a67363fd60e7767ddaa094/3171_logo-full-color3.png
import dayjs from 'dayjs';
import { PRODUCT_LANGUAGE_ASSESSMENT } from '../../../utils/constants';

const examMockConfig = {
	title: 'Assistant chef de projet Support (H/F) - Angleterre',
	clientId: 1244,
	mobileMode: true,
	clientName: 'Siemens',
	product: PRODUCT_LANGUAGE_ASSESSMENT,
	globalTimer: null,
	training: false,
	tutorialUrls: { audio: 'oeo', video: 'fdqfs' },
	mandatoryAnswers: 100,
	reRecording: false,
	mobileURLRedirection: null,
	reminders: [1, 2, 3],
	proctoring: { randomPictures: false, blockCopyPaste: true },
	freeNavigation: true,
	hasVideoAnswers: true,
	logo3: 'https://logo-marque.com/wp-content/uploads/2020/04/Nike-Logo.png',
	logo1:
		'https://exam-static.staging.easyrecrue.tech/clients/1245_d03817904278937e9d769465e6e7b597/nirastate.png',
	recruiter: {
		name: 'Amandine Reitz',
		title: 'HR Director',
		avatar: 'https://picsum.photos/id/1/64/64',
	},
	legal: { display: true, customized: true },
	landing: {
		template: 'background',
		introText:
			'<h3>EasyRECrue</h3>\r\n<p>Bienvenue&nbsp;!<br /> <br /> Nous avons pr&eacute;par&eacute; quelques questions pour faire connaissance avec vous (dur&eacute;e estim&eacute;e : 10 minutes).<br /> <br /> Nous vous conseillons de visiter <a href="https://www.easyrecrue.com/fr/espace-candidat" target="_blank">notre site internet</a> avant de r&eacute;pondre &agrave; cet entretien vid&eacute;o et de vous isoler afin d\'&ecirc;tre dans de bonnes conditions.<br /> <br /> L\'&eacute;quipe easyRECrue</p>',
		medias: [
			{
				category: 'presentation',
				type: 'image',
				url32:
					'https://www.pixel4k.com/wp-content/uploads/2019/03/spiderman-miles-lost-in-space-4k_1553071367.jpg',
				url4: 'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/321_video-fond-1.mp4',
				url: 'https://external-preview.redd.it/ja2rwASy69ySlG3q_Brb8RhD7Sq2gzUEqSxxAbomVS8.png?auto=webp&s=d94d3cd074c7933031287abca2bcfc9735c1c8a6',
			},
			{
				category: 'illustration',
				type: 'video',
				url: 'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/331_carole_video.mp4',
				poster:
					'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/331_carole-poster.png',
			},
			{
				category: 'carouselItem',
				poster:
					'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/331_carole-poster.png',
				type: 'video',
				url: 'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/331_carole_video.mp4',
			},
			{
				category: 'backgroundImage',
				type: 'image',
				url: 'https://i.ibb.co/gj3mDSJ/hire-ready-candidates.jpg',
			},
			{
				category: 'background',
				type: 'video',
				urlTT:
					'https://external-preview.redd.it/ja2rwASy69ySlG3q_Brb8RhD7Sq2gzUEqSxxAbomVS8.png?auto=webp&s=d94d3cd074c7933031287abca2bcfc9735c1c8a6',
				url: 'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/321_video-fond-1.mp4',
				url973: 'https://i.ibb.co/gj3mDSJ/hire-ready-candidates.jpg',
				url735D:
					'https://static.staging.easyrecrue.tech/clients/102_49e64a808bcc22fe1ff9e19fbd8be33c/tbcampaigns/1_55c551a28fea7560bf3f0fb9775ebb8f/321_istock-1173740452.jpg',
				url333:
					'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/321_video-fond-1.mp4',
				url433: 'https://i.ibb.co/gj3mDSJ/hire-ready-candidates.jpg',
				url5: 'https://remeng.rosselcdn.net/sites/default/files/dpistyles_v2/ena_16_9_in_line/2020/04/28/node_147570/11852979/public/2020/04/28/B9723330573Z.1_20200428154821_000%2BGNKFUHR18.1-0.jpg?itok=yj59rxUh1588084666',
				url4: 'https://static.staging.easyrecrue.tech/clients/102_49e64a808bcc22fe1ff9e19fbd8be33c/tbcampaigns/1_55c551a28fea7560bf3f0fb9775ebb8f/321_istock-1173740452.jpg',
			},
			{
				category: 'carouselItem',
				type: 'image',
				url: 'https://static.qualify.talentplatform.eu/clients/215_da529f7a00a67363fd60e7767ddaa094/331_photo1.jpg.png',
			},
			{
				category: 'carouselItem',
				type: 'image',
				url: 'https://static.qualify.talentplatform.eu/clients/215_da529f7a00a67363fd60e7767ddaa094/364_photo4.jpg.png',
			},

			{
				category: 'illustration',
				type: 'video',
				url: 'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/331_carole_video.mp4',
				poster:
					'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/331_carole-poster.png',
			},
			{
				category: 'illustration',
				type: 'image',
				url: 'https://static.qualify.talentplatform.eu/clients/215_da529f7a00a67363fd60e7767ddaa094/364_photo4.jpg.png',
			},
			{
				category: 'illustration',
				type: 'image',
				url: 'https://static.qualify.talentplatform.eu/clients/215_da529f7a00a67363fd60e7767ddaa094/353_photo3.png',
			},
			{
				category: 'logoBig',
				type: 'image',
				url: 'https://logo-marque.com/wp-content/uploads/2020/04/Nike-Logo.png',
				url2: 'https://static.qualify.talentplatform.eu/clients/36_b005e362080f21b1437159251a98e048/3171_logo.png',
				url3: 'https://static.qualify.talentplatform.eu/clients/215_da529f7a00a67363fd60e7767ddaa094/3171_logo-full-color3.png',
			},
		],
	},
	outro: {
		text: "<p>Merci d'avoir particip&eacute; &agrave; cet entretien vid&eacute;o !<br /> <br /> Nous reviendrons vers vous prochainement pour vous tenir inform&eacute; de la suite du processus de recrutement.<br /> <br /> A tr&egrave;s vite,<br /> <br /> L'&eacute;quipe easyRECrue</p>",
	},
	styles1: {
		bannerBackground: '#f8f8f8',
		buttonBackground: '#1cb676',
		bannerText: '#3bc232',
		campaignName: '#DC3128',
		title: '#4D45B6',
		buttonText: '#f7f3ff',
	},
	supportEmail: 'support@easyrecrue.com',
	minBandwidthUp: 102400,
	locale: 'fr-FR',
	survey: true,
	requisites: {
		maxFileSize: 5000000,
		authorizedExtensions: ['jpg', 'png', 'wav', 'doc'],
		items: [
			{
				name: 'CV',
				number: 1,
			},
			{
				name: 'lettre de motivation',
				number: 2,

				maxFileSize: 2000000,
			},
		],
	},
};

// FOR SESSIONS
globalThis.sessionMockStarted = true;

if (globalThis.sessionMockStarted) {
	const today = dayjs().unix();
	const startDate = dayjs().add(1, 'minute').unix();
	const endDate = dayjs().add(1, 'month').unix();
	examMockConfig.session = {
		timeToAccess: 5,
		startDate: startDate,
		endDate: endDate,
	};
}

export default examMockConfig;
