import React, { useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { pulse } from './animation';
import { ChevronRight, ChevronLeft } from '@styled-icons/boxicons-regular';
import Span from './Span';
import { space, layout, color, variant, border, typography, display } from 'styled-system';
import Spinner from 'react-bootstrap/Spinner';
import useCampaignData from '../data/useCampaignData';
import { useTranslation } from '../hooks/useTranslation';
import { paddingProps, stylesProps } from '../types/propTypes';
import PropTypes from 'prop-types';
import { hexToRgbA } from '../utils/tools';
import useEventListener from '../hooks/useEventListener';
import { Flex } from 'rebass/styled-components';
import { Exit } from '@styled-icons/ionicons-outline';

const SpinnerStyled = styled(Spinner)`
	width: 1.3rem;
	margin-right: 3px;
	height: 1.3rem;
`;

const bounceAlphaR = keyframes`
0% {opacity: 1; transform: translateX(0px) scale(1);}
  25%{opacity: 0; transform:translateX(10px) scale(0.9);}
  26%{opacity: 0; transform:translateX(-10px) scale(0.9);}
  55% {opacity: 1; transform: translateX(0px) scale(1);}
`;

const bounceAlphaL = keyframes`
0% {opacity: 1; transform: translateX(0px) scale(1);}
  25%{opacity: 0; transform:translateX(-10px) scale(0.9);}
  26%{opacity: 0; transform:translateX(10px) scale(0.9);}
  55% {opacity: 1; transform: translateX(0px) scale(1);}
`;

const filterBrightness = css`
	filter: brightness(120%);
`;

const Button = styled.button`
	font-family: ${({ theme }) => theme.fonts.body};
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.chevron-button {
		position: absolute;
	}
	&:hover:not([disabled]) {
		${({ withHoverEffect }) => withHoverEffect && filterBrightness}

		#chevronLeft {
			animation-name: ${bounceAlphaL};
			animation-duration: 1.4s;
			animation-delay: 0.2s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}

		#chevronRight {
			animation-name: ${bounceAlphaR};
			animation-duration: 1.4s;
			animation-delay: 0.2s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
	}
	&:not(:focus) {
		animation: ${({ bg, color, ripple, theme }) =>
				ripple && pulse(bg ?? color ?? theme.buttons?.primary.color)}
			2s infinite;
	}
	transition: all 0.3s;

	box-shadow: 0 0 0 0
		${({ bg, color, ripple, theme }) =>
			ripple && hexToRgbA(bg ?? color ?? theme.buttons?.primary.color, 0.7)};
	border: none;
	border-radius: 50px;
	line-height: 17px;
	font-weight: 500;
	font-size: ${({ theme }) => `${theme.fontSizes[1]}px`};
	&:active {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	}
	&:disabled {
		filter: opacity(0.5);
	}
	&:focus-visible {
		font-weight: bolder;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.25rem rgb(0 123 255 / 25%);
	}
	${({ theme }) =>
		variant({
			variants: {
				primary: theme.buttons.primary,
				default: theme.buttons.default,
				reverse: theme.buttons.reverse,
				technical: theme.buttons.technical,
			},
		})}
	${space}
  ${typography}
  ${border}
  ${layout}
  ${color}
  ${display}
`;

export const ButtonEC = React.memo(
	({
		padding = '15px 65px',
		children,
		mobileMode = false,
		focus = false,
		loading = false,
		activeSpaceBar = false,
		loadingText = 'Veuillez patienter',
		chevronRight = false,
		['data-testId']: testId,
		chevronLeft = false,
		exit = false,
		exitIconStyle = false,
		onClick,
		...props
	}) => {
		const clickKeeped = useCallback(
			(e) => {
				e.preventDefault();
				if (!loading) {
					return onClick(e);
				}
				return false;
			},
			[loading, onClick]
		);

		const handleClick = useCallback(
			(e) => {
				e.preventDefault();
				if (activeSpaceBar && e.keyCode === 32) {
					clickKeeped(e);
				}
			},
			[activeSpaceBar, clickKeeped]
		);

		useEventListener('keyup', handleClick, activeSpaceBar);

		const refButton = useCallback(
			(node) => {
				if (node && focus) {
					node.focus();
				}
			},
			[focus]
		);

		return (
			<Button
				data-testid={testId}
				ref={refButton}
				onClick={onClick && clickKeeped}
				p={
					loading
						? [mobileMode ? '15px 15px' : '15px 30px', '15px 30px']
						: mobileMode
						? ['15px 17px', '15px 30px', '15px 40px', '15px 65px']
						: padding
				}
				{...props}
			>
				<Flex justifyContent="center">
					{loading ? (
						<>
							<SpinnerStyled animation="grow" />
							<Span m="2px" mr="8px" display={[mobileMode ? 'none' : 'inherit', 'inherit']}>
								{loadingText}
							</Span>
						</>
					) : (
						<>
							{chevronLeft && (
								<Flex mr={[mobileMode ? '0' : '5px', '5px']} mt={[mobileMode ? '0' : '4px', '4px']}>
									<ChevronLeft
										id="chevronLeft"
										size={12}
										viewBox={mobileMode ? '4 5 15 15' : '6 6 13 13'}
									/>
								</Flex>
							)}
							<Span display={mobileMode ? ['none', 'none', 'inline'] : 'inline'}>{children}</Span>
							{chevronRight && (
								<Flex ml={[mobileMode ? '0' : '5px', '5px']} mt={[mobileMode ? '0' : '4px', '4px']}>
									<ChevronRight
										id="chevronRight"
										size={12}
										viewBox={mobileMode ? '4 5 15 15' : '5 6 13 13'}
									/>
								</Flex>
							)}
							{exit && (
								<Flex ml={[mobileMode ? '0' : '5px', '5px']} mt={[mobileMode ? '0' : '2px', '2px']}>
									<Exit id="exit" size={exitIconStyle ? 33 : 16} />
								</Flex>
							)}
						</>
					)}
				</Flex>
			</Button>
		);
	}
);

export const ButtonClassicCampaign = React.memo(({ children, ...props }) => {
	const { data } = useCampaignData();
	const { t } = useTranslation();

	return (
		<ButtonWithUserStyles loadingText={t('Please wait')} styles={data?.styles} {...props}>
			{children}
		</ButtonWithUserStyles>
	);
});

export const ButtonWithUserStyles = React.memo(({ children, variant, styles, ...props }) => {
	const { data } = useCampaignData();

	if (variant) {
		if (variant === 'reverse' && styles) {
			return (
				<ButtonEC
					border={`1px solid${data?.styles?.buttonBackground}`}
					color={data?.styles?.buttonBackground}
					variant={variant}
					{...props}
				>
					{children}
				</ButtonEC>
			);
		}
		return (
			<ButtonEC variant={variant} {...props}>
				{children}
			</ButtonEC>
		);
	}
	return (
		<ButtonEC
			withHoverEffect
			border={`1px solid${data?.styles?.buttonBackground}`}
			bg={data?.styles?.buttonBackground}
			color={data?.styles?.buttonText}
			{...props}
		>
			{children}
		</ButtonEC>
	);
});
ButtonWithUserStyles.displayName = 'Button';

const propTypes = {
	'data-testId': PropTypes.string,
	loading: PropTypes.bool,
	ripple: PropTypes.bool,
	loadingText: PropTypes.string,
	variant: PropTypes.string,
	onClick: PropTypes.func,
	activeSpaceBar: PropTypes.bool,
	children: PropTypes.node,
	mobileMode: PropTypes.bool,
	focus: PropTypes.bool,
	padding: paddingProps,
	chevronRight: PropTypes.bool,
	chevronLeft: PropTypes.bool,
	exit: PropTypes.bool,
	exitIconStyle: PropTypes.bool,
};

ButtonEC.propTypes = propTypes;
ButtonWithUserStyles.propTypes = { ...propTypes, styles: stylesProps };
ButtonClassicCampaign.propTypes = propTypes;

export default ButtonClassicCampaign;
