import { rest } from "msw";
import { getCGUEndpoint } from "../utils/endpoint";
import { cookieServerName } from "./utils";

export const content =
  '<h2 id="start">GENERAL TERMS AND CONDITIONS OF USE OF THE SERVICE</h2>\n' +
  "\n" +
  '<h3 id="client-name-cgu">EASYRECRUE</h3>\n' +
  "\n" +
  "<p>These General Terms and Conditions of Use (hereinafter the <b>“Terms and Conditions of Use”</b> or <b>“GTCU”</b>)\n" +
  "    govern the relationship between the company EASYRECRUE (hereinafter the <b>“Evaluator”</b>),\n" +
  "    on the one hand, and Users, on the other. Furthermore, since rules are necessary to ensure that the Services are\n" +
  "    used clearly and without risk, these GTCU also set out the rights and responsibilities associated with the platform\n" +
  "    and Services offered by EASYRECRUE, as the producer of the Services. These GTCU therefore form a\n" +
  "    tripartite contractual relationship between the User, the Evaluator and EASYRECRUE.</p>\n" +
  "\n" +
  '<h3 id="preamble">Definitions</h3>\n' +
  "\n" +
  "<p>The terms below will have the following meanings between the parties:</p>\n" +
  "\n" +
  "<ul>\n" +
  "    <li>“Evaluator(s)”: any natural person or legal entity wishing to use the service for the needs of their business,\n" +
  "        including but not limited to any recruiter, manager or evaluator. The Evaluator will be viewed as instigating\n" +
  "        the use of the Service;\n" +
  "    </li>\n" +
  "    <li>“User(s)”: any natural person invited by the Evaluator to use the Service, including but not limited to any\n" +
  "        applicant, student or employee;\n" +
  "    </li>\n" +
  "    <li>“EASYRECRUE”: producer of the Services offered on its online platform, acting in the capacity\n" +
  "        of a technical intermediary;\n" +
  "    </li>\n" +
  "    <li>“Party / Parties”: means the Evaluator, User and EASYRECRUE together;</li>\n" +
  "    <li>“Service(s)”: the service offered by EASYRECRUE.</li>\n" +
  "</ul>\n" +
  "\n" +
  '<h3 id="opposability">Enforceability</h3>\n' +
  "\n" +
  "<p>The Evaluator and the User may only use the services offered by EASYRECRUE subject to acceptance\n" +
  "    of these Terms and Conditions of Use, with which they undertake to comply unreservedly.</p>\n" +
  "<p>EASYRECRUE reserves the right to make any changes it considers necessary and useful to these Terms\n" +
  "    and Conditions of Use. It will use its best endeavours to inform the Evaluator and the User of both the existence\n" +
  "    and effective date of any new Terms and Conditions of Use by any means available to it (emails, online information,\n" +
  "    etc.).</p>\n" +
  "\n" +
  '<h3 id="duration">Term</h3>\n' +
  "\n" +
  "<p>These Terms and Conditions of Use shall apply throughout the period of use of the service by the Evaluator and the\n" +
  "    User.</p>\n" +
  "\n" +
  '<h3 id="services">Presentation of Services</h3>\n' +
  "\n" +
  "<p>EASYRECRUE offers the following Services:</p>\n" +
  "\n" +
  "<p>1/ Pre-recorded video interview</p>\n" +
  "<ul>\n" +
  "    <li>The Evaluator drafts recruitment questionnaires and invites Users to record their responses on video in order to\n" +
  "        evaluate them.\n" +
  "    </li>\n" +
  "    <li>The User can access the Evaluator’s questions once invited to do so or by accessing the Service through a public\n" +
  "        web link.\n" +
  "    </li>\n" +
  "    <li>The User can be given a set amount of time or not for each question, at the Evaluator’s discretion, to prepare\n" +
  "        their answer and then respond to the questions once only, in the order of their choice or an order determined by\n" +
  "        the Evaluator. They are not allowed to start their recording again, in order to preserve the spontaneous nature\n" +
  "        of their answers.\n" +
  "    </li>\n" +
  "    <li>If the test is interrupted by the User, in the case of the addition of a limited time to complete the test, the\n" +
  "        time will continue to run. The User may still return to the test at a later time and will be notified of the\n" +
  "        amount of time remaining if it has not run out.\n" +
  "    </li>\n" +
  "    <li>The User may end the test offered by the Evaluator at any time without having answered all the questions,\n" +
  "        however they will not be able to return and complete it.\n" +
  "    </li>\n" +
  "    <li>The User is not allowed to view their responses prior to the video being forwarded to the Evaluator for\n" +
  "        evaluation.\n" +
  "    </li>\n" +
  "</ul>\n" +
  "\n" +
  "<p>2/ TEST BUILDER</p>\n" +
  "<ul>\n" +
  "    <li>\n" +
  "        The Evaluator drafts recruitment questionnaires and invites Users to:\n" +
  "        <ul>\n" +
  "            <li>record their responses on video in order to evaluate them;</li>\n" +
  "            <li>take part in recruitment tests created specifically for one or more particular use cases specific to the\n" +
  "                Evaluator or the vacancy to be filled;\n" +
  "            </li>\n" +
  "            <li>take part in student selection tests for training courses;</li>\n" +
  "            <li>take part in aptitude tests created by the Evaluator;</li>\n" +
  "            <li>take part in skills tests to check the User’s level in a particular area of activity and, for example,\n" +
  "                plan a training programme for them, or for other purposes;\n" +
  "            </li>\n" +
  "            <li>take part in skills tests used after a training course to check the effectiveness of the training and\n" +
  "                the User’s level of understanding.\n" +
  "            </li>\n" +
  "        </ul>\n" +
  "    </li>\n" +
  "    <li>The User can access the Evaluator’s questions once they have been invited to do so or by accessing the Service\n" +
  "        through a public web link.\n" +
  "    </li>\n" +
  "    <li>The User can be given a set amount of time or not for each question, at the Evaluator’s discretion, to prepare\n" +
  "        their answer and then respond to the questions once only, in the order of their choice or an order determined by\n" +
  "        the Evaluator.\n" +
  "    </li>\n" +
  "    <li>The User can be sent documentation imported by the Evaluator to help the User formulate their answer; the User\n" +
  "        may also be asked to download documents from the Service to complete one or more answers.\n" +
  "    </li>\n" +
  "    <li>If the test is interrupted by the User, in the case of the addition of a limited time to complete the test, the\n" +
  "        time will continue to run. The User may still return to the test at a later time and will be notified of the\n" +
  "        amount of time remaining if it has not run out.\n" +
  "    </li>\n" +
  "</ul>\n" +
  "\n" +
  "<p>3/ EASYSPEAKING</p>\n" +
  "<ul>\n" +
  "    <li>The Evaluator drafts questionnaires and invites Users to take language tests.</li>\n" +
  "    <li>The User can access the Evaluator’s questions once they have been invited to do so or by accessing the Service\n" +
  "        through a public web link.\n" +
  "    </li>\n" +
  "    <li>The User can be given a set amount of time or not for each question, at the Evaluator’s discretion, to prepare\n" +
  "        their answer and then respond to the questions once only, in the order of their choice or an order determined by\n" +
  "        the Evaluator, without being able to restart recording, to maintain the spontaneous nature of their responses.\n" +
  "    </li>\n" +
  "    <li>The User may end the evaluation offered by the Evaluator at any time without having answered all the questions,\n" +
  "        however they will not be able to return and complete it.\n" +
  "    </li>\n" +
  "    <li>The User is not allowed to view their responses prior to the video being forwarded to the Evaluator for\n" +
  "        assessment.\n" +
  "    </li>\n" +
  "    <li>EASYRECRUE uses a subcontractor, hereinafter called the “Language Assessor” to assess the\n" +
  "        User’s language level based on a standard framework.\n" +
  "    </li>\n" +
  "    </ul>\n" +
  "\n" +
  "<p>4/ PLANNER</p>\n" +
  "<p>The Planner tool is used to schedule an interview between one or more User(s) and one or more Evaluator(s).</p>\n" +
  "<ul>\n" +
  "    <li>The Evaluator connects their work calendar to manage their availabilities and allow Users to make\n" +
  "        appointments.\n" +
  "    </li>\n" +
  "    <li>The User can access a personalised page where they can select a slot for an interview with the Evaluator once\n" +
  "        they have been invited to do so or by accessing the Service through a public link;\n" +
  "    </li>\n" +
  "    <li>Appointments are managed by email and text message to handle slot reservations, confirmation of the interview,\n" +
  "        any change or cancellation and automated reminders;\n" +
  "    </li>\n" +
  "    <li>The Planner tool automatically updates the Evaluator’s work calendar as soon as appointments are confirmed;</li>\n" +
  "    <li>The User can contact the Evaluator via the Planner tool or via emails received from them;</li>\n" +
  "    <li>Both the Evaluator and the User can reschedule or cancel an interview or invitation;</li>\n" +
  "    <li>If someone does not attend their interview, the Planner tool can send Users an automated reminder and, if\n" +
  "        necessary, reschedule it.\n" +
  "    </li>\n" +
  "</ul>\n" +
  "\n" +
  "<p>5/ LIVE</p>\n" +
  "<p>The Evaluator and the User can carry out a live, face-to-face video interview using the LIVE tool.</p>\n" +
  "<ul>\n" +
  "    <li>Access to a secured platform;</li>\n" +
  "    <li>Both the Evaluator and the User have access to a space where they can share documents during the live\n" +
  "        interview;\n" +
  "    </li>\n" +
  "    <li>The Evaluator can therefore assess an application in real time using the LIVE tool.</li>\n" +
  "</ul>\n" +
  "\n" +
  "<p>6/ BOT</p>\n" +
  "<p>Users, Evaluators and any visitor the Site can use the online chatbot service.</p>\n" +
  "<p>By accessing the Bot, Users who are looking for a job can:</p>\n" +
  "<ul>\n" +
  "    <li>identify the vacancies available and select any that they think are relevant to them.</li>\n" +
  "    <li>The User will be invited to accept the GTCU and the Data Protection Policy;</li>\n" +
  "    <li>The User can apply for their chosen vacancy by uploading their CV via the chatbot platform or respond manually\n" +
  "        to various questions if they do not wish to upload their CV;\n" +
  "    </li>\n" +
  "    <li>The data entered are analysed and the applicant will be asked to confirm their last name, first name, email\n" +
  "        address and phone number to confirm their application;\n" +
  "    </li>\n" +
  "    <li>Once their information has been confirmed, the User’s application will be forwarded to the Evaluator concerned,\n" +
  "        who will receive all the information provided by the User (CV or information entered manually);\n" +
  "    </li>\n" +
  "    <li>The User may also respond to questions set by the Evaluator, if necessary. Some responses to these questions may\n" +
  "        be classed as critical for the continuation of the recruitment process or for HR management purposes.\n" +
  "    </li>\n" +
  "</ul>\n" +
  "\n" +
  "\n" +
  '<h3 id="conditions">Conditions of access to and use of the Service</h3>\n' +
  "\n" +
  "<p>In order to use the Service, the User must have reached the statutory age of majority and/or have the capacity to\n" +
  "    enter into a contract as defined in law, and have accepted these General Terms and Conditions of Use\n" +
  "    unreservedly.</p>\n" +
  "<p>Use of the Service requires the User to register in advance by completing the registration form, which is accessible\n" +
  "    online on the site. By completing this form, the User confirms that they are familiar with these General Terms and\n" +
  "    Conditions of Use and agrees to adhere to them unreservedly.</p>\n" +
  "<p>The User undertakes to:</p>\n" +
  "<ul>\n" +
  "    <li>register under their real name, with a valid email address;</li>\n" +
  "    <li>inform the Service immediately in the event of improper use of their account or any other security breach.</li>\n" +
  "</ul>\n" +
  "<p>EASYRECRUE cannot be held liable, under any circumstances, for any loss or damage related to a\n" +
  "    keying error by the User.</p>\n" +
  "<p>By completing the registration form, the User warrants to EASYRECRUE that they have provided\n" +
  "    accurate, up-to-date and complete information regarding their identity and contact details. In particular, they\n" +
  "    undertake to provide a valid email address, which they own.</p>\n" +
  "<p>In general terms, the User undertakes, in respect of using the Service, not to engage in actions such as issuing,\n" +
  "    publishing, posting online or disseminating data and/or content that may be illegal or likely to undermine public\n" +
  "    order or infringe the rights of EASYRECRUE or third-party rights holders.</p>\n" +
  "<p>In particular, the User undertakes to comply with rules including but not limited to the following when using the\n" +
  "    Service: provide accurate information when registering for and using the Service; not to use a fake identity for the\n" +
  "    purpose of deceiving another person; comply with the laws in effect and respect the rights of third parties, as well\n" +
  "    as these contractual stipulations; use the Service fairly and in accordance with the legal and regulatory provisions\n" +
  "    in effect and normal practice; respect the intellectual property rights pertaining to the content provided\n" +
  "    by EASYRECRUE and by other Users, and the intellectual property rights of third parties; as a\n" +
  "    consequence, all Users undertake not to reproduce and/or communicate single or multiple items of content via the\n" +
  "    Service to the public, without the authorisation of the holders of the rights to said content, where required; not\n" +
  "    to divert or attempt to divert any of the functionalities of the Service away from its normal use, as defined in\n" +
  "    these General Terms and Conditions of Use; not to use robot software or any other equivalent automated process or\n" +
  "    tool to browse the site; undertake only to communicate to EASYRECRUE and to other Users\n" +
  "    information, the dissemination of which they deem unable to cause harm either to themselves, other\n" +
  "    Users, EASYRECRUE or third parties; not to disseminate data, information or content that is\n" +
  "    defamatory, abusive, obscene, offensive, violent or likely to incite violence, or that is political, racist or\n" +
  "    xenophobic in nature and in general terms, any content that may be contrary to the laws and regulations in effect or\n" +
  "    to high moral standards; not to disseminate data, information or content, the effect of which is to diminish,\n" +
  "    disorganise or prevent normal use of the Service, or to interrupt and/or slow down the normal circulation of\n" +
  "    communications between Users.</p>\n" +
  "<p>In the event of a breach of one or more of these rules by the User, EASYRECRUE reserves the right\n" +
  "    to suspend and/or block the account(s) of the User(s) concerned, automatically delete the contentious messages,\n" +
  "    prevent the publication of their profile in full or in part, and/or block their access to the Service in full or in\n" +
  "    part, temporarily or permanently, and without consideration of any kind.</p>\n" +
  "\n" +
  '<h3 id="service">Access to the Service</h3>\n' +
  "<p>EASYRECRUE does its utmost to ensure access to the Service 24 hours a day, seven days a week,\n" +
  "    except in the case of force majeure or events outside the control of EASYRECRUE, and subject to\n" +
  "    any breakdowns and any maintenance operations necessary for the proper functioning of the site and services.</p>\n" +
  "<p>As a consequence, EASYRECRUE cannot guarantee the availability of the Service, the reliability of\n" +
  "    transmission or the level of performance in terms of response time or quality.</p>\n" +
  "<p>The User and the Evaluator are expected to take all the necessary measures to protect their own data and software,\n" +
  "    especially against potential viruses circulating via the Service.</p>\n" +
  "<p>The equipment required to access the Service must be provided exclusively at the User’s and the Evaluator’s expenses,\n" +
  "    including the telecommunication costs incurred by using it.</p>\n" +
  "<p>The User and the Evaluator are able to access the functionalities of the Service using a user name and public and\n" +
  "    private keys.</p>\n" +
  "<p>User names and public and private keys are personal and confidential. They may only be used by the User and the\n" +
  "    Evaluator. The User and the Evaluator have sole responsibility for the use of their user name and public and private\n" +
  "    keys.</p>\n" +
  "<p>All hardware and software necessary for access to and use of the Service must be provided by the User and the\n" +
  "    Evaluator, and at their sole expenses. The User and the Evaluator must take all appropriate measures to protect\n" +
  "    their own data, computer systems and/or software from contamination by potential viruses.</p>\n" +
  "<p>The use of information, messages or data of any kind, available through the Service, falls under the sole\n" +
  "    responsibility of the User and the Evaluator.</p>\n" +
  "<p>The User and the Evaluator are responsible for all data and information concerning them that they have published\n" +
  "    online and for any content they have provided.</p>\n" +
  "<p>The User and the Evaluator undertake to use only the public and private keys issued to them. Any attempt to access\n" +
  "    the Service using public and private keys other than those issued to them shall be viewed as a cyberattack covered\n" +
  "    by the laws in effect.</p>\n" +
  "<p>In the event of repeated attempts by the User or the Evaluator to access the Service and its functionalities, using\n" +
  "    public and private keys other than those issued to them, EASYRECRUE reserves the right to ban the\n" +
  "    User or the Evaluator from accessing the Service.</p>\n" +
  "\n" +
  '<h3 id="property">Intellectual property - Image right</h3>\n' +
  "<h4>EASYRECRUE rights</h4>\n" +
  "<p>The Service and its constituent texts, trademarks, graphs, images, audio and video elements are the property of\n" +
  "    third-party rights holders and/or EASYRECRUE. Any representation and/or reproduction and/or use\n" +
  "    of the content and services offered, either wholly or in part, by any means whatsoever, without prior written\n" +
  "    consent from the third-party rights holders and/or EASYRECRUE, is strictly prohibited and is\n" +
  "    likely to constitute a breach of copyright as defined in Articles L 335-2 et seq. of the French Intellectual\n" +
  "    Property Code.</p>\n" +
  "<p>Users of the Service are granted permission to view the content of the site on a personal and private basis. Printing\n" +
  "    on paper is authorised solely for making a private copy for the exclusive use of the maker, as defined in Article\n" +
  "    L.122-5-2 of the French Intellectual Property Code.</p>\n" +
  "<p>EASYRECRUE or third-party rights holders are the owners of the Service, in terms of its technical,\n" +
  "    graphic, textual or other components, except for content provided by Users themselves, advertising or applications\n" +
  "    provided by third parties.</p>\n" +
  "<p>EASYRECRUE or third-party rights holders are therefore the sole holders of all the intellectual\n" +
  "    property rights pertaining to the Service, its content and the software and databases required for it to\n" +
  "    function.</p>\n" +
  "<p>EASYRECRUE grants the User and the Evaluator a non-exclusive, personal and non-transferable\n" +
  "    licence, authorising it to use the Service and the data it contains on a strictly personal basis, in accordance with\n" +
  "    these Terms and Conditions of Use and in particular, in accordance with the purpose of the Service.</p>\n" +
  "<p>In order to enable the use and improvement of the Service and in accordance with the purpose of the Service, the User\n" +
  "    and the Evaluator grant EASYRECRUE a non-exclusive licence to use their image and the content and\n" +
  "    data they enter and/or publish online via the Service. By publishing their image, data, information and/or content\n" +
  "    on the Service, the User and the Evaluator accept that they may be disseminated on said Service and made accessible\n" +
  "    to the Users and Evaluators concerned.</p>\n" +
  "<p>EASYRECRUE is authorised to adapt and translate Users and Evaluators’ contents and to reproduce\n" +
  "    said adaptations on any current or future digital medium, stipulated above, for the purpose of providing the\n" +
  "    Service, in particular in different languages.</p>\n" +
  "<p>This licence is granted worldwide, for the term of the contract binding the User and the Evaluator\n" +
  "    to EASYRECRUE.</p>\n" +
  "<h4>Third-party rights</h4>\n" +
  "<p>These Terms and Conditions of Use do not imply any transfer to the User of the Service of any kind of intellectual\n" +
  "    property rights pertaining to elements belonging to third parties, including but not limited to Evaluators, who are\n" +
  "    customers of EASYRECRUE.</p>\n" +
  "<p>The systems, software, structures, infrastructure, databases and content of any kind (texts, images, visuals, music,\n" +
  "    logos, trademarks, databases, etc.), including but not limited to Questionnaires and their associated documents,\n" +
  "    created by Evaluators and used by EASYRECRUE within the Service are protected by all intellectual\n" +
  "    property rights or database producer rights in effect. Any disassembly, decompiling, decryption, extraction, reuse,\n" +
  "    copies and more generally, any reproduction, representation, dissemination or use of any of these elements, in whole\n" +
  "    or in part, without the authorisation of the third-party rights holder, except as provided for in this agreement,\n" +
  "    are strictly prohibited and may result in legal action being taken.</p>\n" +
  "<p>Any reproduction and/or representation, in whole or in part, of any of these elements, without the express\n" +
  "    authorisation of the third-party rights holder, is prohibited and would constitute a forgery, punishable under\n" +
  "    Articles L. 335-2 et seq. of the French Intellectual Property Code.</p>\n" +
  "\n" +
  '<h3 id="links">Hypertext links</h3>\n' +
  "<p>The Service contains hypertext links to other sites. EASYRECRUE cannot, however, verify the\n" +
  "    content of other sites visited, and as a consequence assumes no responsibility for such visits.</p>\n" +
  "\n" +
  '<h3 id="responsability">EASYRECRUE’s responsability</h3>\n' +
  "<p>EASYRECRUE’s responsibility for all of its Services is an obligation to use its best endeavours,\n" +
  "    rather than to achieve a specific result. The Services constitute a technical service provided in its role as an\n" +
  "    intermediary. Under no circumstances may EASYRECRUE or third-party rights holders be held liable\n" +
  "    in the event of an interruption of access to the Service as a result of maintenance operations, updates or technical\n" +
  "    improvements, or to amend the content and/or presentation of the Service, on the understanding that said\n" +
  "    interruptions are announced and fall within normal practice in this respect.</p>\n" +
  "<p>Furthermore, EASYRECRUE may interrupt access to the Service either temporarily or permanently, in\n" +
  "    particular if it ceases to engage in the activity concerned, or if EASYRECRUE is wound up, either\n" +
  "    amicably or by the courts.</p>\n" +
  "<p>Under no circumstances does the information provided by the Services to Evaluators constitute 100% automated\n" +
  "    recommendations.</p>\n" +
  "<p>EASYRECRUEs liability will be limited to objective, direct and proven harm, to the full extent\n" +
  "    allowed by the applicable law and to the extent that EASYRECRUE is found liable for any harm not\n" +
  "    provided for above.</p>\n" +
  "<p>The final decision on the next step for the User of the Service belongs solely to the Evaluator; the Services are\n" +
  "    purely decision-making tools and must not be considered otherwise.</p>\n" +
  "<p>Accordingly, EASYRECRUE cannot be held liable for the final decisions taken by the Evaluator\n" +
  "    following the use of the Services.</p>\n" +
  "<p>Moreover, EASYRECRUE is solely a third party in the relationship between the Evaluator and the\n" +
  "    User, its role as a Platform and intermediary being limited exclusively to putting Evaluators and Users in touch\n" +
  "    with each other and ensuring the smooth technical operation of the Services made available to them.</p>\n" +
  "<p>EASYRECRUE may not be held criminally or contractually liable for situations resulting from an\n" +
  "    incident of force majeure, unforeseen circumstances, the acts of third parties or a victim of harm.</p>\n" +
  "<p>Moreover, EASYRECRUE cannot be held liable in the event of failure to comply with local\n" +
  "    regulations by an Evaluator or User.</p>\n" +
  "\n" +
  '<h3 id="updates">Changes to the Service and guarantees</h3>\n' +
  "<p>EASYRECRUE or third-party rights holders reserve the right to amend the Service, either wholly or\n" +
  "    in part, at any time, as the platform develops.</p>\n" +
  "<p>EASYRECRUE will strive to ensure the Service maintains the same level of functionality as a\n" +
  "    minimum, and undertakes to use its best endeavours to do so. EASYRECRUE cannot be held liable for\n" +
  "    any direct or indirect harm relating to the loss of information or data, or work-related income, on any basis\n" +
  "    whatsoever.</p>\n" +
  "\n" +
  '<h3 id="resiliation">Termination</h3>\n' +
  "<p>EASYRECRUE or third-party beneficiaries may terminate access to the Service as of right, in the\n" +
  "    event of non-compliance with these Terms and Conditions of Use by the Evaluator or User. In this\n" +
  "    case, EASYRECRUE will notify the person concerned of the suspension by email, and will issue them\n" +
  "    with a formal notice to desist. If said notification has gone unheeded after eight (8)\n" +
  "    days, EASYRECRUE may terminate the contract as of right, without any formalities and subject to\n" +
  "    any damages it may claim in compensation for the harm suffered by it, resulting from the cause of the situation\n" +
  "    (failure by the Party in default).</p>\n" +
  "\n" +
  '<h3 id="right">Applicable law</h3>\n' +
  '<p>These GTCU and <a href="#dpp">the Data Protection Policy</a> represent the entire agreement\n' +
  "    between EASYRECRUE and the Evaluator and User and define the terms for the use of the Service.\n" +
  "    This agreement shall prevail over any previous agreement between EASYRECRUE and the Parties.</p>\n" +
  "<p>Should one of these terms and conditions be deemed null and void or invalid, the Parties nonetheless agree that the\n" +
  "    contested clause shall be interpreted on the basis of the Parties’ common intent; the fact that a clause is found to\n" +
  "    be null and void shall not affect the validity of any other clause in the contract.</p>\n" +
  "<p>The conclusion, interpretation and validity of these Terms and Conditions of Use is governed by French law.</p>\n" +
  "<p>Any dispute arising over their interpretation, amendment or performance shall, if it not possible to reach an\n" +
  "    amicable agreement, fall under the exclusive jurisdiction of the French courts.</p>\n" +
  "<p>For any questions related to these Terms and Conditions of Use of the site, please email us at <a\n" +
  '            href="mailto:contact@easyrecrue.com">contact@easyrecrue.com</a>.\n' +
  "</p>";

export default rest.get(
  getCGUEndpoint + "/:productId/:clientId/:locale",
  (req, res, ctx) => {
    console.log("get response", req);

    if (document.cookie.includes(cookieServerName)) {
      return res(
        ctx.delay(400),
        ctx.status(200, "Mocked status"),
        ctx.text(content)
      );
    } else {
      return res(ctx.delay(500), ctx.status(401, "Mocked Unauthorized"));
    }
  }
);
