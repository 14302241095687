import { lazy, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorManager from '../shared/Error/ErrorManager';
import { ErrorBoundary } from 'react-error-boundary';
import 'bootstrap/dist/css/bootstrap.css';
import '../../index.css';
import { useSelectorQueryApp } from '../../reducers/appReducer';
import { ThemeProvider } from 'styled-components';
import { PRODUCT_LANGUAGE_ASSESSMENT } from '../../utils/constants';
import usePromise from 'react-promise-suspense';
import InitBackConnection from '../Root/InitBackConnection';

const LazyMainApp = lazy(() =>
	import(
		/* webpackChunkName: "InitAppCandidate" */
		'../Root/InitCandidateApp'
	)
);
const LazyNotInterested = lazy(() =>
	import(
		/* webpackChunkName: "NotInterested" */
		'./NotInterestedApp'
	)
);
const LazyAuth = lazy(() =>
	import(
		/* webpackChunkName: "Signin" */
		'./SigninApp'
	)
);
const LazyPreview = lazy(() =>
	import(
		/* webpackChunkName: "Preview" */
		'./Preview'
	)
);

const LazyExtends = lazy(() =>
	import(
		/* webpackChunkName: "ExtendsDemand" */
		'./Extend'
	)
);

const LazyMaintenance = lazy(() =>
	import(
		/* webpackChunkName: "Maintenance" */
		'./Maintenance'
	)
);

const BootstrapApp = () => {
	const [usedSignin, setUsedSignin] = useState(false);
	const app = useSelectorQueryApp('app');

	const { default: theme } = usePromise(
		() =>
			app === PRODUCT_LANGUAGE_ASSESSMENT
				? import(
						/* webpackChunkName: "theme-language_assessment" */
						'../../theme/theme-language-assessment'
				  )
				: import(
						/* webpackChunkName: "theme-classic" */
						'../../theme/theme'
				  ),
		[app]
	);

	return (
		<ThemeProvider theme={theme}>
			<InitBackConnection>
				<ErrorBoundary FallbackComponent={ErrorManager}>
					<Switch>
						<Route exact path="/error-tab">
							<div>Please close this tab</div>
						</Route>
						<Route exact path="/extend/:token" component={LazyExtends} />
						<Route exact path="/notinterested/:token" component={LazyNotInterested} />
						<Route path="/preview/:token" component={LazyPreview} />
						{!usedSignin && (
							<Route
								path="/:step/:token"
								render={(routerProps) => (
									<LazyAuth
										{...routerProps}
										setUsedSignin={setUsedSignin}
										usedSignin={usedSignin}
									/>
								)}
							/>
						)}
						<Route path="/maintenance" component={LazyMaintenance} />
						<Route path="/">
							<LazyMainApp usedSignin={usedSignin} setUsedSignin={setUsedSignin} />
						</Route>
					</Switch>
				</ErrorBoundary>
			</InitBackConnection>
		</ThemeProvider>
	);
};

export default BootstrapApp;
