import { rest } from "msw";
import { getEnteredEndpoint } from "../utils/endpoint";
import { cookieServerName } from "./utils";

export const chrono = 8000000;

export default rest.get(getEnteredEndpoint, (req, res, ctx) => {
  console.info("update profile");

  global.profile.status = "entered";

  if (document.cookie.includes(cookieServerName)) {
    return res(ctx.delay(500), ctx.status(204, "Mocked status"));
  } else {
    return res(ctx.delay(500), ctx.status(401, "Mocked Unauthorized"));
  }
});
