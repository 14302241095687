import { rest } from 'msw';
import { getAllStepsEndPoint } from '../utils/endpoint';
import { res, getToken, merge } from './utils';

let error = 0;

export default rest.get(getAllStepsEndPoint, (req, response, ctx) => {
	error++;

	if (process.env.NODE_ENV !== 'test') {
		const token = getToken();
		try {
			const file = require(/* webpackChunkName: "xamConfigMockFile" */
			`./workflow/${
				token.startsWith('ASSESS') ? 'ASSESS/' + token : token
			}/examMockConfig`).default;

			global.campaign = global.campaign ? merge(file, global.campaign) : file;
			return res(ctx.status(200, 'Mocked status Assess'), ctx.json(global.campaign));
		} catch (err) {
			return res(ctx.status(401, 'Mocked Unauthorized'));
		}
	}
});
