import { rest } from "msw";
import { getLaterEndpoint } from "../utils/endpoint";
import { cookieServerName } from "./utils";

export default rest.get(getLaterEndpoint, (req, res, ctx) => {
  if (document.cookie.includes(cookieServerName)) {
    return res(ctx.delay(400), ctx.status(204, "Mocked status"));
  }
  return res(ctx.status(401, "Mocked unauthorized"));
});
