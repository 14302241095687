import Col from 'react-bootstrap/Col';
import { compose } from '@styled-system/core';
import { space } from '@styled-system/space';
import styled from 'styled-components';

export const ColEnhanced = styled(Col)`
	${compose(space)}
`;

export default ColEnhanced;
