import styled, { css } from 'styled-components';

const Label = styled.label`
	font-family: ${({ theme }) => theme.fonts.body};
	font-size: ${({ theme }) => theme.text.medium};
	${({ theme }) => css`
		${theme.mediaQueries.small} {
			font-size: 18px;
		}
	`};
	cursor: pointer;
	position: relative;
	padding-left: 34px;
	user-select: none;
	color: #666d7c;
	line-height: 23px;
	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.4;
			cursor: not-allowed;
		`};

	&input:focus {
		font-weight: bolder;
	}

	max-width: 100%;
	margin-bottom: 5px;
	display: inline-block;
	font-weight: 500;
`;

export default Label;
