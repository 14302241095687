import styled from 'styled-components';
import {
	compose,
	system,
	color,
	position,
	typography,
	layout,
	space,
	border,
	display,
} from 'styled-system';
import { useRef } from 'react';
import { bool } from 'prop-types';

const ImgStyled = styled.img(
	compose(
		system({ transform: true, transition: true }),
		display,
		space,
		color,
		position,
		typography,
		layout,
		border
	)
);

const Img = ({ transition = false, ...props }) => {
	// const [loaded, setLoaded] = useState(false);
	const ref = useRef(null);

	return <ImgStyled transition={transition && 'opacity 0.5s'} ref={ref} {...props} />;
};

Img.propTypes = {
	transition: bool,
};

export default Img;
