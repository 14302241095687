import { keyframes } from 'styled-components';
import { hexToRgbA } from '../utils/tools';

export const pulse = (color, pixel = 15) => keyframes`
to {box-shadow: 0 0 0 ${`${pixel}px`} ${hexToRgbA(color, 0)}
`;

export const clickWave = keyframes`
0% {
 width: 40px;
 height: 40px;
 opacity: 0.35;
 position: relative;
}
 100% {
 width: 200px;
 height: 200px;
 margin-left: -80px;
 margin-top: -80px;
 opacity: 0.0;
}
`;
