import { rest } from "msw";
import { getNotInterstedEndPoint } from "../utils/endpoint";
import { cookieServerName } from "./utils";

export default rest.post(getNotInterstedEndPoint, (req, res, ctx) => {
  console.info("not interested route", req.params);
  if (document.cookie.includes(cookieServerName)) {
    return res(ctx.delay(500), ctx.status(204, "Mocked status"));
  }
  return res(ctx.status(403, "Mocked Forbiden"));
});
