import defaultMock from '../../language_assessment/examMockConfig';

const examMockConfig = {
	...defaultMock,
	title: 'ASSESS-958_TESTER',
	maintenance: true,
	supportPhone: '+331.89.20.39.97',
	maintenanceDate: '19/03/2022',
	maintenanceHour: '17:00',
};

export default examMockConfig;
