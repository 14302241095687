import { colors, buttonPrimary, fontSizes, breakpointBootstrap } from './constants';
import { icimsLogoUrl } from '../utils/config';

export default {
	forms: {
		largeInput: {
			fontSize: 3,
			px: 3,
			py: 2,
		},
		input: {
			color: 'primary',
		},
		select: {},
		textarea: {},
		label: {},
		radio: {},
		checkbox: {},
	},
	breakpoints: breakpointBootstrap,
	mediaQueries: {
		extraSmall: `@media screen and (min-width: 23em)`,
		small: `@media screen and (min-width: ${breakpointBootstrap[0]})`,
		medium: `@media screen and (min-width: ${breakpointBootstrap[1]})`,
		large: `@media screen and (min-width: ${breakpointBootstrap[2]})`,
		extraLarge: `@media screen and (min-width: ${breakpointBootstrap[3]})`,
	},
	fontSizes,
	text: {
		small: `${fontSizes[0]}px`,
		normal: `${fontSizes[1]}px`,
		big: `${fontSizes[2]}px`,
		color: colors.gray2,
	},
	colors: { ...colors },
	title: {
		h1: {
			fontSize: `${fontSizes[5]}px`,
			color: colors.black,
		},
		h2: { fontSize: `${fontSizes[4]}px`, color: colors.black },
		h3: { fontSize: `${fontSizes[3]}px`, color: colors.black },
		h4: { fontSize: `${fontSizes[2]}px`, color: colors.black },
	},
	body: {
		colors: {
			primary: colors.white,
			background: colors.gray2,
		},
	},

	space: [0, 4, 8, 16, 32, 64, 128, 256],
	fonts: {
		body: 'OpenSansR, sans-serif',
		heading: 'inherit',
		monospace: 'Menlo, monospace',
	},
	fontWeights: {
		body: 400,
		heading: 700,
		lightBold: 500,
		bold: 700,
		bolder: 900,
	},
	logo: icimsLogoUrl,
	lineHeights: {
		body: 1.5,
		heading: 1.25,
	},
	shadows: {},
	image: {
		borderRadius: '6px',
	},
	variants: {
		error: colors.red2,
	},
	buttons: {
		primary: buttonPrimary,
		default: { ...buttonPrimary, borderRadius: '4px', padding: '10px' },
		technical: {
			...buttonPrimary,
			color: colors.white,
			borderRadius: '4px',
			backgroundColor: colors.gray,
		},
		success: {
			color: colors.white,
			border: 'rgba(18, 184, 184, 1)',
			bg: 'rgba(18, 184, 184, 1)',
		},
		reverse: {
			backgroundColor: colors.white,
		},
	},
};
