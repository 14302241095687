import { Navbar, Container } from 'react-bootstrap';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { color, layout } from 'styled-system';
import { Span } from '.';
import { string } from 'prop-types';
import { color as colorType } from 'react-extra-prop-types';
import { User } from '@styled-icons/boxicons-solid/User';
import Img from './Img';
import useMediaQueryDevice from '../hooks/useIsSmallDevice';

const CampaignName = styled.div`
	color: ${({ color }) => color};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	${layout}
`;
const NavbarStyled = styled(Navbar)`
	margin-bottom: 20px;
	@media (min-width: 576px) {
		flex-wrap: nowrap;
	}
	flex-wrap: wrap;
	.container {
		@media (min-width: 576px) {
			flex-wrap: nowrap;
			padding-right: inherit;
			padding-left: inherit;
		}
		flex-wrap: wrap;
	}
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.11);
	border-color: #e7e7e7;
	${color}
`;

const NavBarBrandStyled = styled(Navbar.Brand)`
	img {
		max-height: 35px;
	}
`;

const SpanContentRightMobile = styled.span`
	max-width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const NavbarComp = ({
	candidateName = null,
	titleTestId = 'jobTitle',
	textNavbarCenterTestId = 'testSteps',
	logo = '',
	title = '',
	altLogo = '',
	banner = '',
	color = '',
	textNavbarCenter = '',
}) => {
	const isSmallDevice = useMediaQueryDevice(0);

	return (
		<NavbarStyled bg={banner} fixed>
			<Container>
				<NavBarBrandStyled>
					<Img
						width="auto"
						height="auto"
						data-testid="img-navbar"
						src={logo}
						className="d-inline-block align-top"
						alt={altLogo}
						title={altLogo}
					/>
				</NavBarBrandStyled>

				<Flex flexDirection="column">
					{textNavbarCenter && (
						<Span data-testid={textNavbarCenterTestId} fontWeight={200} fontSize={['15px', '20px']}>
							{textNavbarCenter}
						</Span>
					)}
					{isSmallDevice && (
						<SpanContentRightMobile data-testid={`${titleTestId} mobile`}>
							{title}
						</SpanContentRightMobile>
					)}
				</Flex>

				{title && !isSmallDevice && (
					<CampaignName
						data-testid={titleTestId}
						width={['100%', 'initial']}
						maxWidth={['inherit', '200px', '300px', '500px']}
						className="hidden-xs hidden-md"
						color={color}
					>
						{title}
					</CampaignName>
				)}
				{candidateName && <User size={26} />}
			</Container>
		</NavbarStyled>
	);
};

NavbarComp.propTypes = {
	candidateName: string,
	color: colorType,
	logo: string,
	textNavbarCenter: string,
	textNavbarCenterTestId: string,
	banner: string,
	altLogo: string,
	titleTestId: string,
	title: string,
};

export default NavbarComp;
