import styled from 'styled-components';
import { string, number } from 'prop-types';
import { Box } from '../library';
import { memo, useMemo } from 'react';
import { Flex } from 'rebass/styled-components';
import withCampaignStyles from '../HOC/withCampaignStyles';
import useTranslation from '../hooks/useTranslation';

const InputRange = styled.input`
	width: 100%;
	-webkit-appearance: none;
	padding: 0;
	font: inherit;
	display: block;
	outline: none;
	background-color: #f1f3f8;
	border-radius: 11px;
	box-sizing: border-box;
	transition: all 0.2s;
	cursor: pointer;
	&:focus-visible {
		::-webkit-slider-thumb {
			box-shadow: 0 0 4px 2px blue;
		}
		::-webkit-slider-thumb {
			box-shadow: 0 0 4px 2px blue;
		}
	}
	::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 17px;
		height: 17px;
		border-radius: 50%;
		cursor: pointer;
		background: ${({ color }) => color};
		opacity: 0.65;
		position: unset;
		transition: all ease-in-out 0.05s;
	}
	::-moz-range-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 17px;
		height: 17px;
		border-radius: 50%;
		cursor: pointer;
		background: ${({ color }) => color};
		opacity: 0.65;
		position: relative;
		transition: all ease-in-out 0.05s;
	}
`;

const SpanComment = styled.span`
	display: inline-block;
	background: none;
	font-weight: 600;
	color: #505050;
	font-size: 10px;
`;

const Mark = styled.span`
	display: inline-block;
	background: none;
	font-weight: 600;
	color: #455068;
	font-size: 10px;
`;

export const Slider = memo(
	({
		min = 0,
		max = 5,
		step = 1,
		name = 'scale',
		color = 'black',
		textLeft = '',
		textRight = '',
		labelledby = '',
		value = '',
		...props
	}) => {
		const { t } = useTranslation();
		const hint = useMemo(
			() =>
				t(
					'You can use the left and right arrows on your keyboard to decrease or increase the assigned rating'
				),
			[t]
		);

		if (value === '') {
			value = (Math.floor(min + max) / 2).toString();
		}

		return (
			<Box width="100%">
				<span className="sr-only" id={`desc_${name}`}>
					{hint}
				</span>
				<InputRange
					role="slider"
					color={color}
					type="range"
					min={min}
					aria-valuemin={min}
					aria-valuemax={max}
					aria-valuenow={value}
					aria-valuetext={`${value}. ${value === min ? textLeft : ''}${
						value === max ? textRight : ''
					}`}
					aria-labelledby={labelledby}
					aria-describedby={`desc_${name}`}
					max={max}
					step={step}
					name={name}
					value={value}
					{...props}
				/>
				<Flex justifyContent="space-between" padding="5px">
					{Array.from({ length: max }).map((num, i) => (
						<Mark key={i}>{i + 1}</Mark>
					))}
				</Flex>
				<Flex justifyContent="space-between">
					<SpanComment>{textLeft}</SpanComment>
					<SpanComment>{textRight}</SpanComment>
				</Flex>
			</Box>
		);
	}
);

Slider.propTypes = {
	min: number,
	max: number,
	textLeft: string,
	textRight: string,
	color: string,
	typeOfProperty: string,
	step: number,
	name: string,
	labelledby: string,
	value: string,
};

export default withCampaignStyles(Slider, 'color');
