import { rest } from 'msw';
import { getRequisiteEndPoint } from '../utils/endpoint';
import { cookieServerName } from './utils';
import JSON from './document.json';
import examMockConfig from './workflow/template/examMockConfig';

const error = false;

export default rest.post(getRequisiteEndPoint, (req, res, ctx) => {
	if (document.cookie.includes(cookieServerName)) {
		if (error) return res(ctx.delay(400), ctx.status(422, 'Mocked status Error'));

		if (Object.keys(req.body.file).length) {
			global.profile.requisites = [...global.profile.requisites, req.body];
			if (examMockConfig.requisites.length === global.profile.requisites) {
				global.profile.needRequisite = false;
			}
		} else {
			// delete
			global.profile.requisites = global.profile.requisites.filter(
				(elem) => elem.number !== req.body.number
			);
		}

		return res(ctx.delay(400), ctx.status(204, 'Mocked status'));
	}
	return res(ctx.status(401, 'Mocked unauthorized'));
});

export const requisiteDocument = rest.get(getRequisiteEndPoint + '/:number', (req, res, ctx) => {
	if (document.cookie.includes(cookieServerName)) {
		return res(ctx.delay(500), ctx.status(200, 'Mocked status'), ctx.json({ file: JSON }));
	} else {
		return res(ctx.delay(500), ctx.status(401, 'Mocked Unauthorized'));
	}
});
