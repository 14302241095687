import React from 'react';
import styled from 'styled-components';
import { layout } from 'styled-system';
import { string, number } from 'prop-types';

const FormControl = styled.textarea`
	${layout};
	resize: none;
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	&:focus {
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}
	&::placeholder {
		display: block;
		color: #666d7c;
		font-size: 18px;
		opacity: 0.5;
		font-weight: 200;
	}
	&:disabled {
		background-color: #e9ecef;
		opacity: 1;
	}
`;

export const Textarea = React.forwardRef(({ placeholder, cols, rows, ...props }, ref) => (
	<FormControl
		ref={ref}
		as="textarea"
		{...props}
		placeholder={placeholder}
		cols={cols}
		rows={rows}
	/>
));

Textarea.propTypes = {
	placeholder: string,
	cols: number,
	rows: number,
};
