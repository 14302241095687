const apiVersion1 = '/api/v1';

export const getWelcomeEndPoint = '/welcome';

export const getSectionsEndpoint = `${apiVersion1}/step`;
export const sectionsEndpointWithIds = (sectionNumber = 1, questionNumber = 0) =>
	`${getSectionsEndpoint}/${sectionNumber}/${questionNumber}`;

export const getCGUEndpoint = '/legal';
export const getLegalEndpoint = `${apiVersion1}/legal/terms`;
export const getPreviewEndPoint = `${apiVersion1}/campaign/preview`;
export const getNavigationEndpoint = `${apiVersion1}/campaign/navigation`;
export const getAllStepsEndPoint = `${apiVersion1}/campaign`;
export const getCurrentQuestionsEndPoint = `${getSectionsEndpoint}/current`;
export const getMediaRecorderChunk = `${apiVersion1}/chunks`;
export const getInitVideoRecorder = `${apiVersion1}/records/init`;
export const getMediaRecorderEndpoint = `${apiVersion1}/records`;
export const getCandidateAnswer = (questionId) => `${apiVersion1}/answer/${questionId}`;
export const getSigninEndPoint = `${apiVersion1}/signin`;
export const getLaterEndpoint = `${apiVersion1}/candidate/later`;
export const getExtendsEndPoint = `${apiVersion1}/candidate/extend`;
export const getExtentionStatus = `${apiVersion1}/candidate/extensionStatus`;
export const getNotInterstedEndPoint = `${apiVersion1}/candidate/notinterested`;
export const getProfilEndPoint = `${apiVersion1}/candidate/profile`;
export const getRequisiteEndPoint = `${apiVersion1}/candidate/requisite`;
export const getFinishEndpoint = `${apiVersion1}/candidate/finished`;
export const getEnteredEndpoint = `${apiVersion1}/candidate/entered`;
export const getProctoringEndPoint = `${apiVersion1}/proctoring/picture`;
export const getBigFileUploadEndpoint = `${apiVersion1}/technicalTests/upload`;

export const getSurveyEndpoint = `${apiVersion1}/survey`;
export const getFinishSurveyEndpoint = `${apiVersion1}/survey/finish`;
