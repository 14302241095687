import { useState, useCallback, useEffect } from 'react';

const launchLocalStorageFallback = () => {
	import('local-storage-fallback').then((store) =>
		Object.defineProperty(window, 'localStorage', {
			value: store.default,
		})
	);
};

// localstorage wrapper
if (localStorage) {
	try {
		localStorage.setItem('feature_test', 'yes');
		if (localStorage.getItem('feature_test') === 'yes') {
			localStorage.removeItem('feature_test');
			// localStorage is enabled
		} else {
			launchLocalStorageFallback();
		}
	} catch (e) {
		launchLocalStorageFallback();
	}
} else {
	launchLocalStorageFallback();
}

/**
 *
 * @param {"localToken" | "email" | "testStarted" | "deviceVideo" | "deviceAudio" | "idAlreadyShot" | "emailCandidate" | "survey" | "currentQuestionLocal" | "writtenChar"} key
 * @param {any} initialValue
 * @param {"sessionStorage" | "localStorage"} storage
 * @returns {[any,Function, any,string]}
 */
function useLocalStorage(key, initialValue = void 0, storage = 'localStorage') {
	if (!key) {
		throw new Error('you forget to put a key on the local variable storage');
	}

	// State to store our value
	// Pass initial state function to useState so logic is only executed once
	const [storedValue, setValue] = useState(() => {
		try {
			// Get from local storage by key
			const item = window[storage].getItem(key);
			// Parse stored json or if none return initialValue

			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue
			return initialValue;
		}
	});
	// Return a wrapped version of useState's setter function that ...
	// ... persists the new value to localStorage.
	const setItem = useCallback(
		(newValue) => {
			try {
				// Allow value to be a function so we have same API as useState
				const valueToStore = newValue;
				// Save state

				setValue(valueToStore);
				// Save to local storage
				window[storage].setItem(key, JSON.stringify(valueToStore));
				const event = new Event('itemchange');

				event.newValue = newValue;
				event.key = key;
				document.dispatchEvent(event);
			} catch (error) {
				// A more advanced implementation would handle the error case
				console.log(error);
			}
		},
		[key, storage]
	);

	const handleStorage = useCallback(
		(event) => {
			if (event.key === key && event.newValue !== storedValue) {
				setValue(event.newValue || initialValue);
			}
		},
		[storedValue, key, initialValue, setValue]
	);

	useEffect(() => {
		window.addEventListener('storage', handleStorage);
		document.addEventListener('itemchange', handleStorage);
		return () => {
			window.removeEventListener('storage', handleStorage);
			document.removeEventListener('itemchange', handleStorage);
		};
	}, [handleStorage]);

	const deleteValue = useCallback(() => {
		setValue(void 0);
		window[storage].removeItem(key);
		const event = new Event('itemchange');

		event.newValue = void 0;
		event.key = key;
		document.dispatchEvent(event);
	}, [key, storage]);

	return [storedValue, setItem, deleteValue, key];
}

export default useLocalStorage;
