import styled from 'styled-components';
import { space, layout, color, typography, system, compose } from 'styled-system';

const Text = styled.p`
	font-family: ${({ theme }) => theme.fonts.body};
	font-size: ${({ theme }) => theme.text.normal};
	font-weight: ${({ theme }) => theme.fontWeights.body};
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	${compose(system({ textDecoration: true, cursor: true }), space, layout, color, typography)}
`;

export default Text;
