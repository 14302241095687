import { shape } from 'prop-types';
import AuthError from './AuthError';
import { useTranslation } from '../../../hooks/useTranslation';
import withCheckIfCampaignExist from '../../../HOC/withCheckIfCampaignExist';
import { lazy, useEffect } from 'react';
/*import { useSendAnalytics } from '../../Root/AnalyticsProvider';*/
import { useSelectorQueryApp } from '../../../reducers/appReducer';

const ExtensionManager = lazy(() =>
	import(
		/* webpackChunkName: "ExtensionManager" */
		'../../Extends/ExtensionManager'
	)
);

const SessionErrorContainer = lazy(() =>
	import(
		/* webpackChunkName: "SessionErrorContainer" */
		'./Session/Container'
	)
);

function ErrorManager({ error }) {
	const { t } = useTranslation();
	const contentExpiredCampaign = t('The deadline for this interview has passed.');
	const contentExpiredTest = t('The given time to take this test has passed.');
	/*const analytics = useSendAnalytics();
	const step = useSelectorQueryApp('step');*/

	/*useEffect(() => {
		analytics?.logEvent('TechnicalError', { Step: step, Type: 'screen' });
	}, [analytics, step]);*/

	if (error?.toString()?.indexOf('ChunkLoadError') > -1) {
		return window.location.reload(true);
	}

	if (error?.toString()?.indexOf("Unexpected token '<'") > -1) {
		return window.location.reload(true);
	}

	if (error.message === 'EXPIRED_INVITATION') {
		return <ExtensionManager message={error.message} />;
	}

	if (error.message === 'EXPIRED_SPEAKER_INVITATION') {
		return <AuthError content={contentExpiredTest} />;
	}

	if (error.message === 'SERVER_CONNECTION_ERROR') {
		return <AuthError content={t('Connection to the server impossible')} />;
	}

	if (error.message === 'EXPIRED_CAMPAIGN') {
		return <AuthError content={contentExpiredCampaign} />;
	}

	if (error.message === 'TECHNICAL_ERROR') {
		return <AuthError content={t('Technical error: missing or invalid data')} />;
	}

	if (error.message === 'INVALID_CAMPAIGN') {
		return (
			<AuthError
				content={
					t('Your interview or test is not yet available on the platform.') +
					' ' +
					t('Please reconnect later.')
				}
			/>
		);
	}

	if (error.message === 'SESSION_ENDED' || error.message === 'SESSION_LOCKED') {
		return <SessionErrorContainer error={error} />;
	}

	if (error.status === 404) {
		return <AuthError content={error.message} />;
	}
	return (
		<AuthError
			content={t('Technical error: missing or invalid data') + ' (' + error.message + ')'}
		/>
	);
}

ErrorManager.propTypes = {
	error: shape(),
};

export default withCheckIfCampaignExist(ErrorManager);
